import React from 'react';
import {Button, Input} from "antd";
import Highlighter from 'react-highlight-words';
import { Icon } from '@iconify/react';

const withSearchFunction = (WrappedComponent) => {

    return class extends React.Component {

        state = {
            searchText: '',
        };

        columnSearchProps = ( dataIndex, filteredByServerOnly = false ) => ({
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{width: 250, marginBottom: 8, display: 'block'}}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        size="small"
                        style={{width: 90, marginRight: 8}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <Icon icon='iconamoon:search-fill'/>
            ),
            onFilter: (value, record) => {
                if(!filteredByServerOnly) {
                    if (record[dataIndex] != null)
                        return record[dataIndex]
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    else
                        return false;
                }
                else
                    return true;
            },
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            },
            render: text => {
                if(text)
                    return( <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={
                            text.toString()
                        }
                    />);
                else
                    return '';
            },
        });

        handleSearch = (selectedKeys, confirm) => {
            confirm();
            this.setState({searchText: selectedKeys[0]});
        };

        handleReset = clearFilters => {
            clearFilters();
            this.setState({searchText: ''});
        };

        render() {
            return <WrappedComponent columnSearchProps={this.columnSearchProps}  {...this.props }/>
        }
    };
};

export default withSearchFunction;
