import React, { Component, useState, useEffect, useLayoutEffect } from 'react';
import {Form, Input, Modal, DatePicker, Select, Row, Col, Checkbox, Spin, notification} from "antd";
import {Icon} from "@iconify/react";

import {APP_TITLE} from "../../../constants";
import dayjs from "dayjs";
import {securitySearch} from "../../../services/apiService";

const autoWithdrawOperations = ['COUPON', 'DIVIDEND'];
const Option = Select.Option;
const { TextArea } = Input;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
};

const formTailLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const formCurrencyTailLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

const formCheckboxLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16, offset: 4 },
};

const dateFormat = 'YYYY-MM-DD HH:mm';

const dateInit = (value) => {
    return value ? dayjs( value, dateFormat) : dayjs();
};

const dateRules = () => {
    return [{ type: 'object', required: true, message: 'Пожалуйста, выберите дату' }];
};

const currencyRules = () => {
    return [
            { pattern: /^\d+(\.\d+)?$/, required: true, message: 'Пожалуйста, введите сумму' }
        ];
};

const amountRules = ( ) => {
    return [
            { pattern: /^\d+?$/, required: true, message: 'Пожалуйста, введите количество' }
        ];
};

const isinRules = () => {
    return [{ required: true, message: 'Пожалуйста, заполните уникальное значение ISIN' }];
};

const shareRules = () => {
    return [{ required: true, message: 'Пожалуйста, введите акцию' }];
};


const currencyFormField = ({currencies, caption='Валюта', layout=formTailLayout}) => {
    return (
        <FormItem {...layout} label={caption}
                  name={['currency', 'code']}
                  rules={[{required: true, message: 'Пожалуйста, выберите валюту'}]}>
            <Select>
                {currencies.map(curr => <Option value={curr.code}>{curr.name}</Option>)}
            </Select>
        </FormItem>
    )
}


function getFormCurrency(currency, currencies) {
    const currentCurrency = currency ?
        currencies.find(curr => curr.code === currency) :
        currencies.find(curr => curr.isBase === true)
    return currentCurrency;
}

const SimpleForm = ({form, assetItem, currencies}) => {
    const { edate, comment = "", total = 0, currency} = assetItem;
    const currentCurrency = getFormCurrency(currency, currencies);
    const initValues = {
        'date-picker': dateInit(edate),
        total,
        comment,
        currency : currentCurrency
    }

    return (
        <Form form={form}
              initialValues={initValues}
        >
            <FormItem {...formItemLayout} label="Дата"
                name='date-picker'
                rules={dateRules()}>
                <DatePicker showTime placeholder="Дата" format={dateFormat} />
            </FormItem>

            <Row gutter={4}>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Сумма"
                              name='total'
                              rules={currencyRules()}
                    >
                        <Input  placeholder="Cумма"/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    {currencyFormField({currencies})}
                </Col>
            </Row>

            <FormItem {...formItemLayout}  label="Комментарий"
                name='comment' >
                <TextArea placeholder="Комментарий" autosize={{minRows: 2}}/>
            </FormItem>
        </Form>
    )
}

const ShareOperationForm = ({form, assetItem, transactionType, currencies}) =>{
    const { edate,  total = 0, comment, amount, price, isin, withdraw, currency, security = {} } = assetItem;
    const [fetching, setFetching] = useState(false);
    const [shareSearchData, setShareSearchData] = useState(Array.of(security));

    const fetchShares = value => {
        setFetching(true);
        if( value ) {
            securitySearch(value, "SHARE")
                .then((response) => {
                    setFetching(false);
                    setShareSearchData(response);
                })
                .catch(err => {
                    setFetching(false);
                    setShareSearchData([]);
                    // notification["error"]({
                    //     message: APP_TITLE,
                    //     description: err,
                    // });
                });
        }
        else {
            setShareSearchData([]);
        }

    }

    const initValues={
        'date-picker' : dateInit(edate),
        total,
        currency : getFormCurrency(currency, currencies),
        isin : isin,
        security,
        withdraw,
        comment,
        amount,
        price,
    };

    useEffect(() => {
        form.setFieldsValue({
            'date-picker' : dateInit(edate),
            total,
            currency : getFormCurrency(currency, currencies),
            isin,
            security,
            withdraw,
            comment,
            amount,
            price
        });
        setShareSearchData(Array.of(security));
    }, [assetItem]);


    let fields = [
        <FormItem {...formItemLayout} label="Дата"
            name='date-picker' rules={dateRules()}>
                <DatePicker showTime placeholder="Дата" format={dateFormat}/>
        </FormItem>,

        <Row gutter={4}>
            <Col span={12}>
                <FormItem {...formTailLayout} label="Сумма"
                          name='total'
                          rules={currencyRules()}
                >
                    <Input  placeholder="Cумма"/>
                </FormItem>
            </Col>
            <Col span={12}>
                {currencyFormField({currencies})}
            </Col>
        </Row>,

        <FormItem {...formItemLayout} label="Акция"
            name={['security','id']} rules={shareRules()} >
            <Select  prefix={<Icon icon='iconamoon:lock-light' style={{color: 'rgba(0,0,0,.25)'}}/>}
                     showSearch
                     placeholder="Выберите акцию"
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                         true
                     }
                     notFoundContent={fetching ? <Spin size="small"/> : null}
                     onSearch={fetchShares} >

                {shareSearchData.map(d => {
                    const op = <Option key={d.id} value={d.id}>{`${d.shortname} (${d.ticker})`}</Option>
                    console.log(op);
                    return op;
                })}

            </Select>
        </FormItem>,
    ];

    if( autoWithdrawOperations.includes(transactionType) ) {
        fields.push(
            <FormItem {...formCheckboxLayout}
                name='withdraw'
                valuePropName='checked' >
                    <Checkbox >Вывод</Checkbox>
            </FormItem>
        )
    }

    if (transactionType !== 'DIVIDEND')
        fields.push(
            <Row>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Кол-во"
                        name='amount' rules={amountRules()} >
                        <Input placeholder="Количество акций"/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Цена"
                        name='price' rules={currencyRules()} >
                            <Input placeholder="Цена"/>
                    </FormItem>
                </Col>
            </Row>
        );

    return (
        <Form initialValues={initValues} form={form}>
            {fields}
            <FormItem {...formItemLayout} label="Комментарий"
                      name='comment' >
                <TextArea placeholder="Комментарий" autosize={{minRows: 2}}/>
            </FormItem>
        </Form>
    )
}

const FundOperationForm = ({form, assetItem, currencies}) =>{
    const { edate,  total = 0, comment, amount, price, isin, currency, security = {} } = assetItem;
    const [fetching, setFetching] = useState(false);
    const [fundSearchData, setFundSearchData] = useState(Array.of(security));

    const fetchShares = value => {
        setFetching(true);
        if( value ) {
            securitySearch(value, "FUND")
                .then((response) => {
                    setFetching(false);
                    setFundSearchData(response);
                })
                .catch(err => {
                    setFetching(false);
                    setFundSearchData([]);
                    // notification["error"]({
                    //     message: APP_TITLE,
                    //     description: err,
                    // });
                });
        }
        else {
            setFundSearchData([]);
        }

    }

    const initValues={
        'date-picker' : dateInit(edate),
        total,
        currency : getFormCurrency(currency, currencies),
        isin : isin,
        security,
        comment,
        amount,
        price,
    };

    useEffect(() => {
        form.setFieldsValue({
            'date-picker' : dateInit(edate),
            total,
            currency : getFormCurrency(currency, currencies),
            isin,
            security,
            comment,
            amount,
            price
        });
        setFundSearchData(Array.of(security));
    }, [assetItem]);


    let fields = [
        <FormItem {...formItemLayout} label="Дата"
            name='date-picker' rules={dateRules()}>
                <DatePicker showTime placeholder="Дата" format={dateFormat}/>
        </FormItem>,

        <Row gutter={4}>
            <Col span={12}>
                <FormItem {...formTailLayout} label="Сумма"
                          name='total'
                          rules={currencyRules()}
                >
                    <Input  placeholder="Cумма"/>
                </FormItem>
            </Col>
            <Col span={12}>
                {currencyFormField({currencies})}
            </Col>
        </Row>,

        <FormItem {...formItemLayout} label="ETF"
            name={['security','id']} rules={[{ required: true, message: 'Пожалуйста, введите ETF' }]} >
            <Select  prefix={<Icon icon='iconamoon:lock-light' style={{color: 'rgba(0,0,0,.25)'}}/>}
                     showSearch
                     placeholder="Выберите ETF"
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                         true
                     }
                     notFoundContent={fetching ? <Spin size="small"/> : null}
                     onSearch={fetchShares} >

                {fundSearchData.map(d => {
                    const op = <Option key={d.id} value={d.id}>{`${d.shortname} (${d.ticker})`}</Option>
                    console.log(op);
                    return op;
                })}

            </Select>
        </FormItem>,


        <Row>
            <Col span={12}>
                <FormItem {...formTailLayout} label="Кол-во"
                          name='amount' rules={amountRules()} >
                    <Input placeholder="Количество акций"/>
                </FormItem>
            </Col>
            <Col span={12}>
                <FormItem {...formTailLayout} label="Цена"
                          name='price' rules={currencyRules()} >
                    <Input placeholder="Цена"/>
                </FormItem>
            </Col>
        </Row>
    ];

    return (
        <Form initialValues={initValues} form={form}>
            {fields}
            <FormItem {...formItemLayout} label="Комментарий"
                      name='comment' >
                <TextArea placeholder="Комментарий" autosize={{minRows: 2}}/>
            </FormItem>
        </Form>
    )
}

const FutureOperationForm = ({form, assetItem, transactionType, currencies}) =>{
    const { edate,  total = 0, comment, amount, price, currency, margin = 0, security = {} } = assetItem;
    const [fetching, setFetching] = useState(false);
    const [futureSearchData, setFutureSearchData] = useState(Array.of(security));

    const fetchFutures = value => {
        setFetching(true);
        if( value ) {
            securitySearch(value, "FUTURE")
                .then((response) => {
                    setFetching(false);
                    setFutureSearchData(response);
                })
                .catch(err => {
                    setFetching(false);
                    setFutureSearchData([]);
                    // notification["error"]({
                    //     message: APP_TITLE,
                    //     description: err,
                    // });
                });
        }
        else {
            setFutureSearchData([]);
        }

    }

    const initValues={
        'date-picker' : dateInit(edate),
        total,
        currency : getFormCurrency(currency, currencies),
        security,
        comment,
        amount,
        price,
        margin
    };

    useEffect(() => {
        form.setFieldsValue({
            'date-picker' : dateInit(edate),
            total,
            currency : getFormCurrency(currency, currencies),
            security,
            comment,
            amount,
            price,
            margin
        });
        setFutureSearchData(Array.of(security));
    }, [assetItem]);


    let fields = [
        <FormItem {...formItemLayout} label="Дата"
                  name='date-picker' rules={dateRules()}>
            <DatePicker showTime placeholder="Дата" format={dateFormat}/>
        </FormItem>,

        <Row gutter={4}>
            <Col span={12}>
                <FormItem {...formTailLayout} label="Сумма"
                          name='total'
                          rules={currencyRules()}
                >
                    <Input  placeholder="Cумма"/>
                </FormItem>
            </Col>
            <Col span={12}>
                {currencyFormField({currencies})}
            </Col>
        </Row>,

        <FormItem {...formItemLayout} label="Фьючерс"
                  name={['security','id']} rules={[{ required: true, message: 'Пожалуйста, введите фьючерс' }]} >
            <Select  prefix={<Icon icon='iconamoon:lock-light' style={{color: 'rgba(0,0,0,.25)'}}/>}
                     showSearch
                     placeholder="Выберите фьючерс"
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                         true
                     }
                     notFoundContent={fetching ? <Spin size="small"/> : null}
                     onSearch={fetchFutures} >

                {futureSearchData.map(d => {
                    const op = <Option key={d.id} value={d.id}>{`${d.shortname} (${d.ticker})`}</Option>
                    console.log(op);
                    return op;
                })}

            </Select>
        </FormItem>,
    ];

    if (transactionType !== 'VARIATION_MARGIN')
        fields.push(
            <Row>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Кол-во"
                              name='amount' rules={amountRules()} >
                        <Input placeholder="Количество акций"/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Цена"
                              name='price' rules={currencyRules()} >
                        <Input placeholder="Цена"/>
                    </FormItem>
                </Col>
            </Row>
        );

    return (
        <Form initialValues={initValues} form={form}>
            {fields}
            <FormItem {...formItemLayout} label="Маржа"
                      name='margin' rules={currencyRules()} >
                <Input placeholder="Вариационная маржа"/>
            </FormItem>

            <FormItem {...formItemLayout} label="Комментарий"
                      name='comment' >
                <TextArea placeholder="Комментарий" autosize={{minRows: 2}}/>
            </FormItem>
        </Form>
    )
}

const BondOperationForm = ({form, assetItem, transactionType, currencies}) => {

    const {
        edate,
        comment,
        amount,
        total = 0,
        price,
        aci,
        isin,
        withdraw,
        currency,
        security = {}
    } = assetItem;

    const [fetching, setFetching] = useState(false);
    const [bondsSearchData, setBondsSearchData] = useState(Array.of(security));
    const [api, contextHolder] = notification.useNotification();

    const fetchBonds = value => {
        setFetching(true);
        if (value) {
            securitySearch(value, "BOND")
                .then((response) => {
                    setBondsSearchData(response);
                    setFetching(false);
                })
                .catch(err => {
                    setBondsSearchData([]);
                    setFetching(false);
                    console.log(err);
                    // api["error"]({
                    //     message: APP_TITLE,
                    //     description: err,
                    // });
                });
        } else {
            setBondsSearchData([]);
        }

    }


    useEffect(() => {
        form.setFieldsValue({
            'date-picker' : dateInit(edate),
            total,
            currency : getFormCurrency(currency, currencies),
            isin,
            security,
            withdraw,
            comment,
            amount,
            price,
            aci
        });
        setBondsSearchData(Array.of(security));
    }, [assetItem]);


    let fileds = [
        <FormItem {...formItemLayout} label="Дата"
                  name='date-picker' rules={dateRules()}>
            <DatePicker showTime placeholder="Дата" format={dateFormat}/>
        </FormItem>,

        <Row gutter={4}>
            <Col span={12}>
                <FormItem {...formTailLayout} label="Сумма"
                          name='total'
                          rules={currencyRules()}
                >
                    <Input placeholder="Cумма"/>
                </FormItem>
            </Col>
            <Col span={12}>
                {currencyFormField({currencies})}
            </Col>
        </Row>,

        <FormItem {...formItemLayout} label="ISIN"
                  name={['security','ticker']}
                  id={'ticker'}
                  rules={[{ required: true, message: 'Пожалуйста, заполните уникальное значение ISIN' }]}>
            <Input disabled placeholder="ISIN акции"/>
        </FormItem>,

        <FormItem {...formItemLayout} label="Облигация"
                  name={['security','id']}>
            <Select prefix={<Icon icon='iconamoon:lock-light' style={{color: 'rgba(0,0,0,.25)'}}/>}
                    showSearch
                    placeholder="Выберите облигацию"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        true
                    }
                    notFoundContent={fetching ? <Spin size="small"/> : null}
                    onChange={(value) => {
                        const item = bondsSearchData.find( bond => bond.id === value);
                        if(item)
                            form.setFieldsValue({
                                security: {
                                    ticker: item.isin
                                }
                            })
                    }}
                    onSearch={fetchBonds}
                >
                {bondsSearchData.map(d => {
                    const op = <Option key={d.id} value={d.id}>{`${d.shortname} (${d.ticker})`}</Option>
                    console.log(op);
                    return op;
                })}

            </Select>
        </FormItem>,
    ];

    if (autoWithdrawOperations.includes(transactionType)) {
        fileds.push(
            <FormItem {...formCheckboxLayout}
                      name='withdraw'
                      valuePropName='checked'>
                <Checkbox>Вывод</Checkbox>
            </FormItem>
        )
    }

    if (transactionType === 'SELL_BOND' || transactionType === 'BUY_BOND') {
        fileds.push(
            <Row gutter={8}>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Кол-во"
                              name='amount' rules={amountRules()}>
                        <Input placeholder="Количество облигаций"/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Цена"
                              name='price' rules={currencyRules()}>
                        <Input placeholder="Цена"/>
                    </FormItem>
                </Col>
            </Row>
        );
        fileds.push(
            <FormItem {...formItemLayout} label="НКД"
                      name='aci' rules={currencyRules()}>
                <Input placeholder="Накопленный ком. доход (НКД)"/>
            </FormItem>
        );
    }

    fileds.push(
        <FormItem {...formItemLayout} label="Комментарий"
                  name='comment'>
            <TextArea placeholder="Комментарий" autosize={{minRows: 2}}/>
        </FormItem>);


    return (
        <Form form={form}
            initialValues={{
                'date-picker' : dateInit(edate),
                total,
                currency : getFormCurrency(currency, currencies),
                isin,
                security,
                withdraw,
                comment,
                amount,
                price,
                aci
            }}>
            {fileds}
        </Form>
    )
}

const CurrencyOperationForm = ({form, assetItem, transactionType, currencies}) => {

    const {
        edate,
        comment,
        total = 0,
        ratio,
        currency,
        exchangedCurrency
    } = assetItem;

    const initValues={
        'date-picker' : dateInit(edate),
        total,
        comment,
        ratio,
        currency : getFormCurrency(currency, currencies),
        exchangedCurrency : getFormCurrency(exchangedCurrency, currencies),
    }

    return (
        <Form form={form} initialValues={initValues}>
            <FormItem {...formItemLayout} label="Дата"
                      name='date-picker' rules={dateRules()}>
                <DatePicker showTime placeholder="Дата" format={dateFormat}/>
            </FormItem>

            <Row gutter={4}>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Сумма"
                              name='total'
                              rules={currencyRules()}
                    >
                        <Input  placeholder="Cумма"/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    {currencyFormField({currencies, caption: 'Валюта покупки', layout: formCurrencyTailLayout})}
                </Col>
            </Row>

            <Row gutter={4}>
                <Col span={12}>
                    <FormItem {...formTailLayout} label="Курс"
                              name='ratio' rules={currencyRules()}>
                        <Input placeholder="Курс"/>
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem {...formCurrencyTailLayout} label="Валюта продажи"
                              name={['exchangedCurrency', 'code']}
                              rules={[{required: true, message: 'Пожалуйста, выберите валюту продажи.'}]}>
                        <Select>
                            {currencies.map(curr => <Option key={curr.code} value={curr.code}>{curr.name}</Option>)}
                        </Select>
                    </FormItem>
                </Col>
            </Row>


            <FormItem {...formItemLayout} label="Комментарий"
                      name='comment' >
                <TextArea placeholder="Комментарий" autosize={{minRows: 2}}/>
            </FormItem>
        </Form>
    )
}

const TransactionFormFactory = ({onChangeTransactionType,
                               asset, transaction,
                               currencies,
                               form, aiService}) => {

    const { id : idItem, transactionType } = transaction;
    const {allowedTransactions = []} = asset;

    useEffect(() => {
        form.resetFields();
    }, [transaction]);

    const getForm = () => {
        switch (transactionType) {
            case 'PUTTING' :
            case 'WITHDRAW':
            case 'INCOME' :
            case 'OUTCOME':
            case 'COMMISSION':
            case 'PERCENTS':
            case 'TAX':
            case 'RETURN_TAX':
                return <SimpleForm
                    form={form}
                    currencies={currencies}
                    assetItem={transaction}
                />
            case 'BUY_SHARE':
            case 'SELL_SHARE':
            case 'DIVIDEND':
                return <ShareOperationForm
                    form={form}
                    currencies={currencies}
                    transactionType={transactionType}
                    assetItem={transaction}
                    aiService={aiService}
                />

            case 'BUY_FUND':
            case 'SELL_FUND':
                return <FundOperationForm
                    form={form}
                    currencies={currencies}
                    transactionType={transactionType}
                    assetItem={transaction}
                    aiService={aiService}
                />

            case 'CLOSE_BOND':
            case 'COUPON':
            case 'AMORTIZATION':
            case 'SELL_BOND':
            case 'BUY_BOND':
                return <BondOperationForm
                    form={form}
                    currencies={currencies}
                    transactionType={transactionType}
                    assetItem={transaction}
                    aiService={aiService}
                />
            case 'SELL_FUTURE':
            case 'BUY_FUTURE':
            case 'VARIATION_MARGIN':
                return <FutureOperationForm
                    form={form}
                    currencies={currencies}
                    transactionType={transactionType}
                    assetItem={transaction}
                    aiService={aiService}
                />
            case 'CURRENCY_EXCHANGE':
                return <CurrencyOperationForm
                    form={form}
                    currencies={currencies}
                    transactionType={transactionType}
                    assetItem={transaction}
                    />
            default:
                return null;
        }
    }

    return(
        <div>
            <Select style={{ width: '100%', marginBottom: 20 }}
                    onChange={onChangeTransactionType}
                    value = {transactionType}
                    disabled = {idItem}
            >
                {allowedTransactions.map(transactionType => <Option key={transactionType.transactionType}>{transactionType.transactionDescription}</Option>)}
            </Select>
            {getForm()}
        </div>
    )
}


const TransactionDialog = ({handleConfirmation, modalDialogVisible,
                              transaction = {id: undefined}, asset,
                              currencies =[]
                          }) => {
    const [currentTransaction, setCurrentTransaction] = useState(transaction);
    const [form] = Form.useForm();

    useEffect(() => {
        setCurrentTransaction(transaction);
    }, [transaction]);

    const handleOk = () => {
        const {id, transId } = transaction;

        form.validateFields().then( fieldsValue => {
            let values = {
                ...fieldsValue,
                edate: fieldsValue['date-picker'].format('YYYY-MM-DDTHH:mm:ss'),
                transactionType : currentTransaction.transactionType,
                currency: fieldsValue.currency.code,
                id,
                transId
            };
            if(currentTransaction.transactionType === 'CURRENCY_EXCHANGE')
                values = {
                    ...values,
                    exchangedCurrency: fieldsValue.exchangedCurrency.code
                }

            handleConfirmation(true, values);
        });
    }

    const handleCancel = () => {
        handleConfirmation(false);
    }

    const handleTransactionType = (transactionType={}) => {
        const {allowedTransactions = []} = asset;
        setCurrentTransaction(
            {
                ...currentTransaction,
                transactionType
            }
        );
    }

    return(
        <div>
            <Modal
                title="Добавить сделку"
                style={{ top: 20}}
                open={modalDialogVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
            >
                <TransactionFormFactory
                    form={form}
                    asset={asset}
                    transaction={currentTransaction}
                    currencies={currencies}
                    onChangeTransactionType={handleTransactionType}
                />
            </Modal>
        </div>
    );
}

export default TransactionDialog;
