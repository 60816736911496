import {configureStore} from "@reduxjs/toolkit";
import authReducer from '../features/auth/authSlice'
import bondsReducer from '../features/bonds/bondsSlice'
import assetReducer from '../features/portfolioAssets/assetsSlice'
import transactionsReducer from '../features/portfolioTransactions/transactionsSlice'
import currenciesReducer from '../features/main/currenciesSlice'
import portfolioReportReducer from '../features/portfolioReport/portfolioReportSlice'
import {TOKEN} from "../constants";

const store = configureStore({
    reducer: {
        auth: authReducer,
        bonds: bondsReducer,
        assets: assetReducer,
        transactions: transactionsReducer,
        currencies: currenciesReducer,
        portfolioReport: portfolioReportReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

store.subscribe(() => {
    // @ts-ignore
    const token : string = store.getState().auth.keycloak.token;
    if(token)
        localStorage.setItem(TOKEN, token);
    else
        localStorage.removeItem(TOKEN);
})

export default store;

