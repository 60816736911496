import React from 'react'
import {Button, Switch} from "antd";

import './AssetViewerTools.less'


const AssetViewerTools = ({createAsset, activeOnly, onActive = () => {}}) => {

    return(
        <div className='ip-assetviewer-tools'>
            <Button type="link"
                    onClick={() => createAsset()}
            >
                Новый актив
            </Button>
            <div className='ip-active-only'>
                <span>Только активные </span>
                <span><Switch
                    defaultChecked={activeOnly}
                    onChange={onActive}  />
                </span>
            </div>
        </div>
    )
}

export default AssetViewerTools;
