import React, {useState} from 'react';
import {Menu} from "antd";
import {useLocation} from "react-router-dom";

import './MainHeader.less'

const MainHeader = ({items = []}) => {
    const location = useLocation();

    const keys = (path) => {
        var segments = path.split('/');
        return segments[2];
    }

    return(
        <div className='main-page-header'>
            <div className='main-page-logo'>
                I-Portfolio
            </div>
            <Menu
                className='main-page-menu'
                mode='horizontal'
                //defaultSelectedKeys={['entrance']}
                theme="light"
                items={items}
                selectedKeys={keys(location.pathname)}
            />
        </div>
    )

}

export default MainHeader;
