import React, {useEffect} from 'react'

import './Asset.less'
import {Icon} from "@iconify/react";
import {Button, Popconfirm} from "antd";
import {useDispatch} from "react-redux";
import {assetTotalReturnAsync} from "../assetsSlice";

const Asset = ({asset, assetTypeName, onEdit, onDelete, children}) => {
    const {name, id, totalReturn = {}} = asset;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(assetTotalReturnAsync({assetId : id}));
    }, [dispatch, id]);

    return (
        <div className='asset'>
            <div className='asset-header'>
                <h4>{name}</h4>
                <div className='asset-type'>{assetTypeName}</div>
            </div>
            <div className='asset-content'>
                {children}
            </div>
            <div className='asset-footer'>
                <ul>
                    <li>
                        <Button type='link' onClick={() => {
                            onEdit(asset);
                        }}>
                            <Icon fontSize={20} icon='material-symbols:edit-outline'/>
                        </Button>
                    </li>
                    <li>
                        <Popconfirm
                            title='Вы уверены что хотите удалить актив?'
                            description={<div>{name}</div>}
                            onConfirm={()=>{onDelete(asset)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type='link'>
                                <Icon fontSize={20} icon='material-symbols:delete-outline'/>
                            </Button>
                        </Popconfirm>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Asset;
