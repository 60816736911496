import React, {useCallback, useEffect, useState} from 'react';
import SharesSider from "./SharesSider";
import {Layout} from "antd";
import {Outlet} from "react-router-dom";
const {Content, Sider} = Layout;

const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const SharesPage = () => {
    const [helmet] = useState(initHelmet);

    return(
        <Layout hasSider className='main-layout-wrapper'>
            <Sider>
                <SharesSider />
            </Sider>
            <Content className='main-layout-content'>
                Здесь будут акции
            </Content>
        </Layout>
    )
}

export default SharesPage;
