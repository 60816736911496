import React from 'react';

const SharesSider = () => {
    return(
        <>
            Акции фильтр
        </>
    )
}

export default SharesSider;
