import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {bonds} from '../../services/apiService'
import {initKeycloak, login} from "../auth/authSlice";

export const bondsSlice = createSlice({
    name: 'bonds',
    initialState: {
        filters: {
            durations: [], rates: [], reliability: [], bondTypes: [], active: ['active'], offerTypes: ['bid'], additional: []
        },
        loading : false,
        bonds: [],
        pagination : {
            showSizeChanger:false,
            total: 0,
            pageSize: 20,
            current: 0
        },
        tableFilters: undefined,
        sorting: {
            field: 'yield',
            order: 'descend'
        }
    },
    reducers: {
        request: (state, {payload: {filters, tableFilters, sorting}}) => {
            state.filters = filters;
            state.tableFilters = tableFilters;
            state.sorting = sorting;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(loadAsyncBonds.pending, (state, action) => {
            console.log('bonds.loadBonds.pending');
            state.loading = true;
        })
        .addCase(loadAsyncBonds.fulfilled, (state, action) => {
            console.log('bonds.loadBonds.fulfilled');
            const {content: bonds = [], pageable: page, totalElements} = action.payload;
            const pagination = {
                total: totalElements,
                pageSize: page.pageSize,
                current: page.pageNumber + 1
            };
            state.bonds = bonds;
            state.pagination = pagination;
            state.loading = false;
        })
        .addCase(loadAsyncBonds.rejected, (state, action) => {
            console.log('bonds.loadBonds.rejected');
            state.loading = false;
        })
    }
})

const { request } = bondsSlice.actions

const loadAsyncBonds = createAsyncThunk('bonds/loadAsyncBonds', ({filters, pagination, tableFilters, sorting}, {getState}) => {
    return bonds(filters, pagination, tableFilters, sorting);
})


export const loadBonds = (filters, pagination, tableFilters, sorting, dispatch) => {
    try{
        dispatch(request({filters, tableFilters, sorting}))
        dispatch(loadAsyncBonds({filters, pagination, tableFilters, sorting}))
    }
    catch (e) {
        console.log(e)
    }
}

export const selectBondsFilter = state => state.bonds.filters;
export const selectBonds = state => state.bonds.bonds;
export const selectBondsPagination = state => state.bonds.pagination;
export const selectBondsTableFilters = state => state.bonds.tableFilters;

export default bondsSlice.reducer
