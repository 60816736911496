import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, Popconfirm, Table} from "antd";
import moment from "moment";
import {Icon} from "@iconify/react";

import './Transactions.less';
import TransactionDialog from "./TransactionDialog";
import {
    createTransactionAsync,
    deleteTransactionAsync,
    updateTransactionAsync,
    setCreatingTransaction,
    closeEditingDialog, loadTransactionAsync, loadAsyncTransactions
} from "../transactionsSlice";
import transactionDataColumnProps from "./transactionDateColumnProps";
import transactionDetailsColumnProps from "./transactionDetailsColumnProps";
import {securitySearch} from "../../../services/apiService";

const Transactions = ({asset, loading}) => {
    const transactionGroupsTypeFilter = [
        {text: 'Акции', value: 'shares'},
        {text: 'Облигации', value: 'bonds'},
        {text: 'Фьючерсы', value: 'futures'},
        {text: 'ETF', value: 'etfs'},
        {text: 'Комиссии', value: 'commissions'},
        {text: 'Прочее', value: 'others'},
    ];

    const [searchDateRange, setSearchDateRange] = useState([]);
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchDateRange(selectedKeys[0]);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchDateRange([]);
    }


    const dispatch = useDispatch();
    const currencies = useSelector(state => state.currencies.currencies)
    const editingTransaction = useSelector(state => state.transactions.editingTransaction);
    const openEditingDialog = useSelector(state => state.transactions.openEditingDialog);
    const {filteredInfo = {}} = asset;

    const {transactions = [], pagination} = asset;

    const handleEditTransaction = (asset, id) => {
        dispatch(loadTransactionAsync({asset,id}));
    }

    const handleDeleteTransaction = (asset, id) => {
        dispatch(deleteTransactionAsync({asset, id}));
    }

    const [fetching, setFetching] = useState(false);
    const [secSearchData, setSecSearchData] = useState([]);
    const [secType, setSecType] = useState("SHARE");

    const handleDetailSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // setSecSearchData(selectedKeys);
    };
    const handleDetailReset = (clearFilters) => {
        clearFilters();
        setSecSearchData([])
    }

    const fetchSec = value => {
        setFetching(true);
        if( value ) {
            securitySearch(value, secType)
                .then((response) => {
                    setFetching(false);
                    setSecSearchData(response);
                })
                .catch(err => {
                    setFetching(false);
                    setSecSearchData([]);
                });
        }
        else {
            setSecSearchData([]);
        }
    }

    const itemsColumns = [
        {
            title: 'Дата'
            , dataIndex: 'edate'
            , width: 160
            , render: (text, record) => {
                const {edate} = record;
                return (moment(edate).format('DD.MM.YYYY HH:mm:ss'));
            }
            , ...transactionDataColumnProps('edate', searchInput,
                handleSearch, handleReset,
                searchDateRange, setSearchDateRange)
            ,filteredValue: filteredInfo.edate || []
        }
        , {
            title: 'Сумма'
            , dataIndex: 'amount'
            , align: 'right'
            , render: (text, record) => {
                const {currency: code, total = 0.00} = record;
                return (
                    <span style={{color: total > 0 ? 'green' : 'red'}}>
                        {Math.abs(total).toLocaleString('ru-RU', {style: 'currency', currency: code})}
                    </span>
                )
            }
        }
        , {
            title: 'Операция'
            , dataIndex: 'transactionType'
            , render: (text) => {
                const {transactionDescription} = text;
                return (transactionDescription);
            }
            ,filters: transactionGroupsTypeFilter
            ,filteredValue: filteredInfo.transactionType || null
        }
        , {
            title: 'Детали'
            , dataIndex: 'details'
            , ...transactionDetailsColumnProps('details', searchInput,
                handleDetailSearch, handleDetailReset,
                fetching,
                secType, setSecType,
                secSearchData, fetchSec)
            ,filteredValue: filteredInfo.details || null
        }
        , {
            title: 'Комментарий'
            , dataIndex: 'comment'
        }
        , {
            title: <Icon fontSize={20} icon='lucide:more-horizontal'/>
            , key: 'action'
            , align: 'center'
            , width: 80
            , render: ( record) => {
                const {hasParent} = record;
                if(!hasParent)
                    return (
                        <div className='ip-transactions'>
                            <Button type='link'
                                onClick={() => handleEditTransaction(asset, record.id)}>
                                <Icon fontSize={14} icon='material-symbols:edit-outline'/>
                            </Button>
                            <Divider type="vertical"/>
                            <Popconfirm
                                title='Вы уверены что хотите удалить сделку?'
                                onConfirm={()=>{handleDeleteTransaction(asset, record.id)}}
                                okText="Yes"
                                cancelText="No"
                            >

                                <Button type='link' >
                                    <Icon fontSize={14} icon='material-symbols:delete-outline'/>
                                </Button>
                            </Popconfirm>
                        </div>
                    );
            },
        }
    ]

    return (
        <div>
            <Button type="link"
                    onClick={()=>{
                        dispatch(setCreatingTransaction())
                    }}
            >
                Добавить сделку
            </Button>
            <div className='ai-asset-page-context'>
                <Table
                    caption={() => asset ? asset.name + ' (' + asset.description + ')' : ''}
                    columns={itemsColumns}
                    rowKey='id'
                    dataSource={transactions}
                    loading={loading}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        dispatch(loadAsyncTransactions({asset, pagination, filters}));
                    }}
                    size="small"
                    bordered
                />
            </div>
            <TransactionDialog
                asset={asset}
                transaction={editingTransaction}
                currencies={currencies}
                handleConfirmation={(result, values) => {
                    if(result) {
                        const {id} = values;
                        if(id) {
                            dispatch(updateTransactionAsync({asset, transaction: values}))
                        }
                        else {
                            dispatch(createTransactionAsync({asset, transaction: values}))
                        }
                    }
                    else
                        dispatch(closeEditingDialog())
                }}
                modalDialogVisible={openEditingDialog}
            />
        </div>
    );
};

export default Transactions;
