import React from 'react';
import './PortfolioFooter.less';

const PortfolioFooter = () => {
    return(
        <div className="footer">
            <div className="copyright" >
                © 2023 I-Protfolio. Все права защищены.
            </div>
        </div>
    );
}

export default PortfolioFooter;
