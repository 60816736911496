import React, {ReactNode, useEffect, useState} from 'react';
import {Button, Col, Layout, Menu, Row} from 'antd';
import {Helmet} from 'react-helmet-async';
import './less/home-page.less'
import {MailOutlined} from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import {getChildrenToRender} from "../home1/utils";
import TweenOne from "rc-tween-one";
import {useDispatch, useSelector} from "react-redux";
import {login, selectIsAuthenticated} from "../auth/authSlice";
import {HomeHeader} from "../../components/header";
import {Link} from "react-router-dom";
import {PortfolioFooter} from "../../components/footer";

const {Header, Content, Footer} = Layout;


const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const items = [
    {
        label: 'О сервисе',
        key: 'about',
        //icon: <MailOutlined />,
    },
    {
        label:
            <Link to='/app'>Вход</Link>,
        key: 'entrance',
    },
    {
        label: 'Возможности',
        key: 'features',
    },
    {
        label: 'Вопросы',
        key: 'faq',
    },
    {
        label: 'Цены',
        key: 'price',
    },
    {
        label: 'Контакты',
        key: 'contacts',
    },
]


export const HomePage = (props) => {
    const [helmet] = useState(initHelmet);
    const [title, setTitle] = useState(helmet.title);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        if (helmet.isRoot) {
            setTitle('React Antd Demo');
        } else {
            setTitle(`${helmet.title} | React Antd Demo`);
        }
    }, [helmet]);

    return (
        <Layout className="home-page-wrapper"
                hasSider={false}
        >
            <>
                <Helmet>
                    <html lang="en"/>
                    <title>{title}</title>
                    <meta name="description" content={helmet.description}/>
                </Helmet>
            </>

            <Header>
                <HomeHeader items={items}></HomeHeader>
            </Header>
            <Content>
                {/*banner*/}
                <div className='home-page-banner'>
                    <div className='banner-wrapper'>
                        <div className='banner-title-wrapper'>
                            <h1 className="banner-title">
                                I-Portfolio
                            </h1>
                            <div className="banner-explain">
                                <span><p>Программа ведения и учета инвестиций</p></span>
                            </div>
                            <div className="banner-content" >
                                <span><p>Начните использовать бесплатно.</p></span>
                            </div>
                            <Button type='primary' className="banner-button-wrapper">
                                Регистрация
                            </Button>
                        </div>
                        <div className='banner-img'>
                            <img src='https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ' width="100%" alt="img" />
                        </div>
                    </div>
                </div>

                {/*features*/}
                <div className="home-page-features features-wrapper">
                    <div className="title-wrapper">
                        <h1 name="title" className="title-h1">Сервис учета инвестиционных активов</h1>
                        <div name="content" className="title-content">Все инвестиции в одном месте</div>
                    </div>
                    <div>
                        <Row className="feature-block-wrapper">
                            <Col name="block0" span={8} className='feature-block'>
                                <div className="feature-icon">
                                    <img src="https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png" width="100%"
                                         alt="img"/>
                                </div>
                                <div className="feature-text">
                                    <h2 className="feature-title">
                                        Анализ портфеля
                                    </h2>
                                    <div className="feature-content">
                                        Вводите параметры портфеля и получайте информацию в случае превышения долей, рисков. Автоматически рассчитывайте доли акций в портфеле.
                                    </div>
                                </div>
                            </Col>
                            <Col name="block0" span={8} className='feature-block'>
                                <div className="feature-icon">
                                    <img src="https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png" width="100%"
                                         alt="img"/>
                                </div>
                                <div className="feature-text">
                                    <h2 className="feature-title">
                                        Тестовые портфели
                                    </h2>
                                    <div className="feature-content">
                                        Создавайте тестовые портфели и сверяйте прибыльность как по текущему портфелю, так и по тестовым портфелям.
                                    </div>
                                </div>
                            </Col>
                            <Col name="block0" span={8} className='feature-block'>
                                <div className="feature-icon">
                                    <img src="https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png" width="100%"
                                         alt="img"/>
                                </div>
                                <div className="feature-text">
                                    <h2 className="feature-title">
                                        Автозагрузка сделок
                                    </h2>
                                    <div className="feature-content">
                                        Загружайте сделки из отчетов брокера. Если ваш брокер не входит в список, обратитесь в службу поддержки.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* цены */}
                <div className="home-page-pricing">
                    <div className="pricing-title-wrapper">
                        <h1 name="title" className="pricing-title-h1">
                            <p>
                                Выберите подходящий план
                            </p>
                        </h1>
                    </div>
                    <div className="pricing-content-wrapper">
                        <Row>
                            <Col className="pricing-block" span={12}>
                                <div className="pricing-block-box">
                                    <div className="pricing-top-wrapper">
                                        <div className="pricing-name">
                                            <font><font>Бесплатно</font></font></div>
                                        <h1 className="pricing-money">
                                            0 ₽
                                        </h1>
                                    </div>
                                    <div className="pricing-content">
                                        <span>
                                            140-500 Мбит/с<br/>
                                            140 ГБ-50 ТБ（含）<br/>
                                            14 500 ГБ 14 <br/>
                                            дюймов<br/>
                                            14 дюймов<br/>
                                            14 дюймов
                                        </span>
                                    </div>
                                    <i className="pricing-line"></i>
                                    <div className="pricing-button-wrapper">
                                        <Button href="#" className="pricing-button" >
                                            Регистрация
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="pricing-block" span={12}>
                                <div className="pricing-block-box active">
                                    <div className="pricing-top-wrapper">
                                        <div className="pricing-name">
                                            Базовый
                                        </div>
                                        <h1 className="pricing-money">
                                            199 ₽
                                        </h1>
                                    </div>
                                    <div className="pricing-content">
                                        <span>
                                            140-500 Мбит/с<br/>
                                            140 ГБ-50 ТБ（含）<br/>
                                            14 500 ГБ 14 <br/>
                                            дюймов<br/>
                                            14 дюймов<br/>
                                            14 дюймов
                                        </span>
                                    </div>
                                    <i className="pricing-line"></i>
                                    <div className="pricing-button-wrapper">
                                        <Button href="#" type="primary" className="pricing-button" >
                                            Купить сейчас
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
            <Footer>
                <PortfolioFooter />
            </Footer>
        </Layout>
    );
};
