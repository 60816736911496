import React from 'react';
import Deposit from "./Deposit";
import StockExchange from "./StockExchange";


export default class AssetFactory {
    static build(asset, onEdit, onDelete) {
        switch (asset.assetType) {
            case 'DEPOSIT':
                return <Deposit key={asset.id} asset={asset} onEdit={onEdit} onDelete={onDelete}/>;
            case 'STOCK_EXCHANGE':
                return <StockExchange key={asset.id} asset={asset} onEdit={onEdit} onDelete={onDelete}/>;
            default:
                return undefined;
        }
    }
}

