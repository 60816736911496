export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const FILTER_BASE_PATH = '/stock/v1/securities/filters';
export const ASSETS_BASE_PATH = '/portfolio/v1/assets';
export const PORTFOLIO_ANALYZE_PATH = '/portfolio/v1/analyze';
export const BROKERS_BASE_PATH = '/portfolio/v1/brokers';
export const LOADERS_BASE_PATH = '/report-loader/v1';
export const CURRENCY_PATH = '/stock/v1/currencies';
export const SECURITY_SEARCH_PATH = '/stock/v1/securities/search';

export const APP_TITLE = 'i-Portfolio';
export const TOKEN = 'TOKEN';

export const RELIABILITY_TYPES = [
    {id: 'HIGH', name : 'Макс.надежность', className: 'highReliability'},
    {id: 'GOOD', name : 'Надежный', className: 'goodReliability'},
    {id: 'WARNING', name : 'Проблемный', className: 'warningReliability'},
    {id: 'POOR', name : 'Преддефолтный', className: 'poorReliability'},
    {id: 'UNRELIABLE', name : 'Дефолт', className: 'unreliableReliability'},
    {id: 'UNKNOWN', name : 'Неопределено', className: 'unknownReliability'},
];


