import React, {useEffect, useState} from 'react'

import './AssetViewer.less'
import {useDispatch, useSelector} from "react-redux";
import {loadAssets} from "./assetsSlice";
import AssetDialog from './AssetDialog'
import AssetFactory from './assets/AssetFactory'

import './Assets.less'

const Assets = ({assets, onEdit = () => {}, onDelete=()=>{}}) => {

    const renderedAssets = assets.map((asset) => AssetFactory.build(asset, onEdit, onDelete));

    return (
        <div className='ip-assets'>
            {renderedAssets}
        </div>

    )
}

export default Assets;
