import React from 'react';
import {Checkbox} from "antd";
import moment from "moment";
import DetailTable from "../../../components/detailtable/DetailTable";
import {DetailInfoWrapper} from "./DetailInfoWrapper";

const ReportTransactionsViewer = ({
                                      reportTransactions,
                                      caption,
                                      isCheckColumn = false,
                                      checkedList = [], checkAll = false, indeterminate = false,
                                      onHeaderCheckboxHandler = () => {},
                                      onCheckboxHandler = () => {}
                                  }) => {

    if (reportTransactions.length > 0)
        return (
            <DetailInfoWrapper caption={caption}>
                <DetailTable>
                    <thead>
                    <tr>
                        {isCheckColumn &&
                            <th>
                                <Checkbox value="control" checked={checkAll} indeterminate={indeterminate}
                                          onChange={onHeaderCheckboxHandler}
                                />
                            </th>
                        }
                        <th>Дата</th>
                        <th>Сделка</th>
                        <th>Сумма</th>
                        <th>Детали</th>
                        <th>Комментарий</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reportTransactions.map(wrapper => {
                        const {transaction = {}, status, id: idWrapper} = wrapper;
                        const {id, edate, currency, total, description, details, comment} = transaction;
                        return (
                            <tr key={`${idWrapper}`}>
                                {isCheckColumn &&
                                    <td align="center">
                                        <Checkbox value={idWrapper}
                                                  onChange={onCheckboxHandler}
                                                  checked={checkedList.includes(idWrapper)}/>
                                    </td>
                                }
                                <td>{moment(edate).format('DD.MM.YYYY HH:mm:ss')}</td>
                                <td>{description}</td>
                                <td align='right'>
                                    {total.toLocaleString('ru-RU', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                        currency
                                    })}
                                </td>
                                <td>{details}</td>
                                <td>{comment}</td>
                                <td>{status}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </DetailTable>
            </DetailInfoWrapper>
        );
}

export default ReportTransactionsViewer;
