import React, {useCallback, useEffect, useState} from 'react';
import {PageHeader, PageWidget} from "../../components/contentComponents";
import AssetViewer from "./AssetViewer";

const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const PortfolioAssetsPage = () => {
    const [helmet] = useState(initHelmet);

    return(
        <>
            <PageHeader caption='Активы' />
            <PageWidget>
                <AssetViewer />
            </PageWidget>
        </>
    )
}

export default PortfolioAssetsPage;
