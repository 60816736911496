import React from 'react'
import {DetailInfoWrapper} from "./DetailInfoWrapper";
import DetailTable from "../../../components/detailtable/DetailTable";
import moment from "moment";

import './StockExchangeDetailInfo.less';


const StockExchangeDetailInfo = ({detailInfo = {}}) => {
    const {cash, bonds, shares, funds} = detailInfo;

    const renderCash = () => {
        if(cash) {
            return (
                <DetailInfoWrapper caption='Деньги'>
                    <DetailTable>
                        <thead>
                        <tr>
                            <th>Валюта</th>
                            <th>Сумма</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cash.map( item => {
                            const {currency, total} = item;
                            return (
                                <tr key={currency}>
                                    <td >{currency}</td>
                                    <td align='right'>
                                        {total.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency})}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </DetailTable>
                </DetailInfoWrapper>
            )
        }
    }

    const renderShareTable = (currency, securities = []) => {
        var sum = securities.reduce((accumulator, {total}) => {
            return accumulator + total
        },0);

        return(
            <div>
                <div>{currency}</div>
                <DetailTable>
                    <thead>
                    <tr>
                        <th>Тикер</th>
                        <th>Наименование</th>
                        <th>Количество</th>
                        <th>Сумма</th>
                        <th>Валюта</th>
                    </tr>
                    </thead>
                    <tbody>
                    {securities.map( ({security, amount, total}) => {
                        const {id, ticker, name, shortname} = security;
                        return <tr key={id}>
                            <td>{ticker}</td>
                            <td>{shortname}</td>
                            <td align='right'>{amount}</td>
                            <td align='right'>{total.toLocaleString('ru-RU', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: 'currency', currency
                            })}</td>
                            <td>{currency}</td>
                        </tr>
                    })
                    }
                    <tr>
                        <td colSpan={3}>Итого</td>
                        <td align='right'>{sum.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            style: 'currency', currency
                        })}</td>
                        <td>{currency}</td>
                    </tr>
                    </tbody>
                </DetailTable>
            </div>
        )
    }


    const renderShares = () => {
        if(shares) {
            return (
                <DetailInfoWrapper caption='Акции'>
                    {shares.map(item => {
                        const {currency, securities} = item;
                        return renderShareTable(currency, securities);
                    })}
                </DetailInfoWrapper>
            )
        }
    }

    const renderBondTable = (currency, securities = []) => {
        var sum = securities.reduce((accumulator, {total}) => {
            return accumulator + total
        },0);

        return(
            <div>
                <div>{currency}</div>
            <DetailTable>
                <thead>
                <tr>
                    <th>Тикер</th>
                    <th>Наименование</th>
                    <th>Количество</th>
                    <th>Сумма</th>
                    <th>Валюта</th>
                </tr>
                </thead>
                <tbody>
                    {securities.map( ({security, amount, total}) => {
                            const {id, ticker, name, shortname} = security;
                            return <tr key={id}>
                                <td>{ticker}</td>
                                <td>{shortname}</td>
                                <td align='right'>{amount}</td>
                                <td align='right'>{total.toLocaleString('ru-RU', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency', currency
                                })}</td>
                                <td>{currency}</td>
                            </tr>
                        })
                    }
                    <tr>
                        <td colSpan={3}>Итого</td>
                        <td align='right'>{sum.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            style: 'currency', currency
                        })}</td>
                        <td>{currency}</td>
                    </tr>
                </tbody>
            </DetailTable>
            </div>
        )
    }

    const renderBonds = () => {
        if(bonds) {
            return (
                <DetailInfoWrapper caption='Облигации'>
                    {bonds.map(item => {
                        const {currency, securities} = item;
                        return renderBondTable(currency, securities);
                    })}
                </DetailInfoWrapper>
            )
        }
    }

    const renderFunds = () => {
        if(funds) {
            return (
                <DetailInfoWrapper caption='ETF'>
                    {funds.map(item => {
                        const {currency, securities} = item;
                        return renderShareTable(currency, securities);
                    })}
                </DetailInfoWrapper>
            )
        }
    }

    return(
        <div>
            {renderCash()}
            {renderShares()}
            {renderBonds()}
            {renderFunds()}
        </div>
    );
}

export default StockExchangeDetailInfo;
