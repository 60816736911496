import React, {useCallback, useEffect, useState} from 'react';
import {PageHeader, PageWidget} from "../../components/contentComponents";
import TransactionsCaption from "./TransactionsCaption";
import {useSelector} from "react-redux";
import AssetFactory from "../portfolioAssets/assets/AssetFactory";
import TransactionsFactory from "./TransactionsFactory";
import AssetInfo from "./AssetInfo";

const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const PortfolioTransactionsPage = () => {
    const [helmet] = useState(initHelmet);

    const currentAsset = useSelector(state => state.transactions.currentAsset);
    const {name=''} = currentAsset;

    const renderedTransactions = TransactionsFactory.build(currentAsset);

    return(
        <>
            <PageHeader caption='Сделки' />
            <PageWidget>
                <TransactionsCaption />
            </PageWidget>
            <PageWidget caption = {`${name}`}>
                <AssetInfo asset={currentAsset} />
                {renderedTransactions}
            </PageWidget>
        </>
    )
}

export default PortfolioTransactionsPage;
