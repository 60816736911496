import React, {useCallback, useEffect, useState} from 'react';
import {Layout} from "antd";
import {Outlet} from "react-router-dom";
import PortfolioSider from "./PortfolioSider";
const {Sider, Content} = Layout;


const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const PortfolioPage = () => {
    const [helmet] = useState(initHelmet);

    return(
        <Layout hasSider className='main-layout-wrapper'>
            <Sider>
                <PortfolioSider />
            </Sider>
            <Content className='main-layout-content'>
                <Outlet/>
            </Content>
        </Layout>
    )
}

export default PortfolioPage;
