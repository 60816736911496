import React, {useEffect} from 'react';
import Asset from './Asset'
import {Col, Row} from "antd";
import {useDispatch} from "react-redux";
import {assetTotalReturnAsync} from "../assetsSlice";
import TotalReturn from "./TotalReturn";


const StockExchange = ({asset, onEdit, onDelete}) => {
    const {description, id, totalReturn = {}} = asset;
    const {investmentTotal = 0, currentTotal = 0, profitTotal = 0, annualReturn = 0} = totalReturn;
    const currency = 'RUB';

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(assetTotalReturnAsync({assetId : id}));
    }, [dispatch, id]);

    return(
        <Asset
            asset={asset}
            assetTypeName='Брокерский счет'
            onEdit={onEdit}
            onDelete={onDelete}
        >
            <TotalReturn
                currency={currency}
                investmentTotal={investmentTotal}
                currentTotal={currentTotal}
                profitTotal={profitTotal}
                annualReturn={annualReturn}
            />
        </Asset>
    )
}

export default StockExchange;
