import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {currencies} from '../../services/apiService'

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState: {
        currencies: []
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAsyncCurrencies.fulfilled, (state, action) => {
                state.currencies = action.payload;
            })
            .addCase(loadAsyncCurrencies.rejected, (state, action) => {
                state.currencies = [];
            })
    }
})

export const loadAsyncCurrencies = createAsyncThunk('currencies/loadAsyncCurrencies', () => {
    return currencies();
})


export default currenciesSlice.reducer
