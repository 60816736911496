import {
    PORTFOLIO_ANALYZE_PATH,
    API_BASE_URL,
    ASSETS_BASE_PATH,
    FILTER_BASE_PATH,
    BROKERS_BASE_PATH,
    CURRENCY_PATH, LOADERS_BASE_PATH,
    SECURITY_SEARCH_PATH,
    TOKEN
} from "../constants";
import axios from 'axios';


const getInstance = (timeout) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(TOKEN)
    };

    return axios.create({
        baseURL: API_BASE_URL,
        timeout: timeout,
        headers: headers,
        withCredentials: true
    });
}

const get = (url, options, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.get(url, options)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}


const post = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.post(url, values).then(response => {
        if (response.status !== 200 && response.status !== 201) {
            return Promise.reject(response);
        }
        return response.data;
    });
}

const put = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.put(url, values)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}

const patch = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);
    return instance.patch(url, values);
}

const del = (url, options, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.delete(url, options).then(response => {
        if (response.status !== 200) {
            return Promise.reject(response);
        }
        return response.data;
    });
}


const orderDirectionConverter = (order) => {
    switch (order) {
        case 'descend' : return 'desc';
        case 'ascend' : return 'asc';
        default: return '';
    }
}

const fieldsFilterToParams = (fieldsFilter) => {
    const fieldParams = {};
    Object.keys(fieldsFilter).filter(item => fieldsFilter[item]).forEach((item) => {
        fieldParams[item] = fieldsFilter[item].join(',');
    });
    return fieldParams;
}


/********************************************************
 * BONDS
 *
 */
export const bonds = ({durations, rates, reliability, bondTypes, active, offerTypes, additional}, pagination, fieldsFilter, sorting) => {
    const {field = '', order = ''} = sorting;
    let params = {
        rates: rates.join(','),
        durations: durations.join(','),
        reliability: reliability.join(','),
        types: bondTypes.join(','),
        active: active.join(','),
        offerTypes: offerTypes.join(','),
        additional: additional.join(','),
        size: pagination.pageSize,
        page: pagination.current - 1,
        sort: `${field},${orderDirectionConverter(order)}`
    };
    if (fieldsFilter) {
        const fieldParams = {};
        Object.keys(fieldsFilter).filter(item => fieldsFilter[item]).forEach((item) => {
            fieldParams[item] = fieldsFilter[item].join(',');
        });
        params = {
            ...params,
            ...fieldParams
        };
    }

    return get(`${FILTER_BASE_PATH}/bonds`, {
        params: params
    });
}

//*************************** ASSETS *****************************************


export const assets = (activeOnly) => {
    const params = {activeOnly}
    return get(`${ASSETS_BASE_PATH}`, {params});
}

export const createAsset = (asset) => {
    return post(`${ASSETS_BASE_PATH}`, asset);
}

export const updateAsset = (asset) => {
    const {id} = asset;
    return put(`${ASSETS_BASE_PATH}/${id}`, asset);
}

export const deleteAsset = (id) => {
    return del(`${ASSETS_BASE_PATH}/${id}`);
}

export const brokers = () => {
    return get(`${BROKERS_BASE_PATH}`);
}

export const assetDetailInfo = (asset) => {
    const {id} = asset;
    return get(`${ASSETS_BASE_PATH}/${id}/details-info`);
}

export const assetTotalReturn = (assetId) => {
    return get(`${ASSETS_BASE_PATH}/${assetId}/total`);
}


export const updateReportLoaderParams = (assetId, params) => {
    return patch(`${ASSETS_BASE_PATH}/${assetId}/report-params`, params)
}


//*************************** TRANSACTIONS *****************************************

export const transactions = (assetId, pagination, filters = []) => {
    let params = {
        size: pagination.pageSize,
        page: pagination.current - 1,
        sort: `edate,desc`,
        ...fieldsFilterToParams(filters),
    };

    return get(`${ASSETS_BASE_PATH}/${assetId}/transactions`, {params});
}

export const transaction = (assetId, id) => {
    return get(`${ASSETS_BASE_PATH}/${assetId}/transactions/${id}`);
}

export const createTransaction = (assetId, transaction) => {
    return post(`${ASSETS_BASE_PATH}/${assetId}/transactions`, transaction);
}

export const updateTransaction = (assetId, transaction) => {
    const {id} = transaction;
    return put(`${ASSETS_BASE_PATH}/${assetId}/transactions/${id}`, transaction);
}

export const deleteTransaction = (assetId, transactionId) => {
    return del(`${ASSETS_BASE_PATH}/${assetId}/transactions/${transactionId}`);
}

//******************* ПОИСК *******************************************************

export const securitySearch = (value, securityType) => {
    var params = {
        value,
        securityType
    }
    return get(`${SECURITY_SEARCH_PATH}/any-match`, {params});
}

//**********************************************************************************

export const currencies = () => {
    return get(`${CURRENCY_PATH}/tracked`);
}

//**************************** ANALYZE **********************************
export const totalReturn = () => {
    return get(`${PORTFOLIO_ANALYZE_PATH}/total`);
}

export const assetTypeAllocations = () => {
    return get(`${PORTFOLIO_ANALYZE_PATH}/asset-type-allocations`);
}

export const currencyAllocations = () => {
    return get(`${PORTFOLIO_ANALYZE_PATH}/currency-allocations`);
}

//*************************** БРОКЕРСКИЕ ОТЧЕТЫ ************************************

export const uploadApiTransactions = (assetId) => {
    return post(`${LOADERS_BASE_PATH}/assets/${assetId}/api-upload`);
}

export const getLoadedTransaction = (assetId) => {
    return get(`${LOADERS_BASE_PATH}/assets/${assetId}/transactions`);
}

export const getReportLoadHistoryItems = (assetId) => {
    return get(`${LOADERS_BASE_PATH}/assets/${assetId}/load-history`);
}

export const saveTransactions = (assetId, transactions) => {
    return post(`${LOADERS_BASE_PATH}/assets/${assetId}/save`, transactions);
}



