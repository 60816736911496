import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import Home from "../features/home1";
import {ConfigProvider} from "antd";
import './less/app.less';
import {HomePage} from "../features/home/HomePage";
import MainPage from "../features/main/MainPage";
import BondsPage from "../features/bonds/BondsPage";
import PortfolioPage from "../features/portfolio/PortfolioPage";
import SharesPage from "../features/shares/SharesPage";
import PortfolioAssetsPage from "../features/portfolioAssets/PortfolioAssetsPage";
import PortfolioTransactionsPage from "../features/portfolioTransactions/PortfolioTransactionsPage";
import PortfolioReportPage from "../features/portfolioReport/PortfolioReportPage";
import PortfolioHistoryPage from "../features/portfolioHistory/PortfolioHistoryPage";

const App = (props) => {

    return (
        <HelmetProvider>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: "'Exo 2', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                        fontSize: 14
                    },
                    components: {
                        Layout: {
                            colorBgHeader: '#fff',
                            colorBgLayout: '#fff',
                        },
                        Menu: {
                            horizontalItemSelectedColor: '#316593',
                            radiusItem: 0,
                            itemBorderRadius: 0,
                            colorItemBgSelected: "#e6f4ff",
                            itemSelectedBg: "#258bce",
                            itemSelectedColor: "rgba(0,0,0,.65)",

                            itemHoverBg: "transparent",
                            colorItemBgHover: "transparent",
                            itemHoverColor: "#225f96"
                        }
                    }
                }}
            >

                <div>
                    <BrowserRouter>
                        <Routes>
                            <Route exact path="/" element={<HomePage/>}/>
                            <Route path="app" element={<MainPage/>}>
                                <Route index element={<Navigate to="portfolio" replace/>}/>
                                <Route path="portfolio" element={<PortfolioPage/>}>
                                    <Route index element={<Navigate to="report" replace/>}/>
                                    <Route path="report" element={<PortfolioReportPage/>}/>
                                    <Route path="assets" element={<PortfolioAssetsPage/>}/>
                                    <Route path="transactions" element={<PortfolioTransactionsPage/>}/>
                                    <Route path="history" element={<PortfolioHistoryPage/>}/>
                                </Route>
                                <Route path="bonds" element={<BondsPage/>}/>
                                <Route path="shares" element={<SharesPage/>}/>
                            </Route>
                            <Route path="/home" element={<Home/>}/>
                        </Routes>
                    </BrowserRouter>
                </div>
            </ConfigProvider>
        </HelmetProvider>
    );
}

export default App;
