import React, {useEffect, useState} from 'react'
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentAsset} from "./transactionsSlice";
import {loadAssets} from "../portfolioAssets/assetsSlice";

const TransactionsCaption = () => {

    const dispatch = useDispatch();

    const assets = useSelector(state => state.assets.assets);
    const currentAsset = useSelector(state => state.transactions.currentAsset);
    const loadingAssets = useSelector(state => state.assets.loading);

    const id = currentAsset ? currentAsset.id : null;

    const selectOptions = assets
        .filter(asset => asset.isActive)
        .map( asset => {
            return {
                value: asset.id,
                label: asset.name
            }
    })

    useEffect(() => {
        loadAssets(dispatch);
    },[dispatch])

    return(
        <div>
            <div>
                <Select
                    defaultValue={id}
                    style={{ width: 300 }}
                    options={selectOptions}
                    loading={loadingAssets}
                    onChange={(value) => {
                        const asset = assets.find( asset => asset.id === value )
                        setCurrentAsset(asset, dispatch);
                    }}
                />
            </div>
        </div>
    )
}

export default TransactionsCaption;
