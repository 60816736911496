import React from "react";

import './DetailInfoWrapper.less';

export const DetailInfoWrapper = ({caption, children}) => {
    return(
        <div className='ip-detail-info-wrapper'>
            <h4>{caption}</h4>
            {children}
        </div>
    )
}
