import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    assetTypeAllocations, currencyAllocations,
    totalReturn
} from '../../services/apiService'

export const portfolioReportSlice = createSlice({
    name: 'portfolioReport',
    initialState: {
        totalReturn: {},
        assetTypeAllocations: [],
        currencyAllocations: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(totalReturnAsync.fulfilled, (state, action) => {
                state.totalReturn = action.payload;
            })
            .addCase(assetTypeAllocationsAsync.fulfilled, (state, action) => {
                state.assetTypeAllocations = action.payload.filter(item => {
                    const {total = 0} = item;
                    return total !== 0;
                })
            })
            .addCase(currencyAllocationsAsync.fulfilled, (state, action) => {
                state.currencyAllocations = action.payload.filter(item => {
                    const {total = 0} = item;
                    return total !== 0;
                })
            })
    }
})

export const totalReturnAsync = createAsyncThunk('portfolioReport/totalReturnAsync', () => {
    return totalReturn();
})

export const assetTypeAllocationsAsync = createAsyncThunk('portfolioReport/assetTypeAllocationsAsync', () => {
    return assetTypeAllocations();
})

export const currencyAllocationsAsync = createAsyncThunk('portfolioReport/currencyAllocationsAsync', () => {
    return currencyAllocations();
})

const {} = portfolioReportSlice.actions;


export default portfolioReportSlice.reducer
