import React from 'react'
import './PageWidget.less'

const PageWidget = ({caption, children}) => {
    return(
        <div className="ip-page-widget">
            <h2>{caption}</h2>
            {children}
        </div>
    )
}

export  default PageWidget;
