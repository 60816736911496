import React, {useCallback, useEffect, useState} from 'react';
import {PageHeader, PageWidget} from "../../components/contentComponents";
import FundsDistribution from "./FundsDistribution";
import Profitability from "./Profitability";
import {ReportWidget} from "../../components/portfolioReports";

import './PortfolioReportPage.less'
import AssetTypeAllocationReport from "./AssetTypeAllocationReport";
import CurrencyAllocationReport from "./CurrencyAllocationReport";

const PortfolioReportPage = () => {

    return(
        <>
            <PageHeader caption='Отчет отчет по порфтелю' />
            <PageWidget caption='Доходность' >
                <Profitability />
            </PageWidget>
            <PageWidget caption='Распределение'>
                <div>
                    <AssetTypeAllocationReport />
                    <CurrencyAllocationReport />
                    <ReportWidget
                        caption='По отраслям'
                    />
                </div>
            </PageWidget>

        </>
    )
}

export default PortfolioReportPage;
