import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import DetailTable from "../../components/detailtable/DetailTable";
import {totalReturnAsync} from "./portfolioReportSlice";

import './Profitability.less'

const Profitability = () => {
    const totalReturn = useSelector(state => state.portfolioReport.totalReturn);

    const { investmentTotal = 0, currentTotal = 0, profitTotal = 0, annualReturn=0 } = totalReturn;
    const currency = 'RUB';
    const profit = investmentTotal !== 0 ? profitTotal/investmentTotal : 0;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(totalReturnAsync());
    }, [dispatch]);

    return(
        <div className='ip-profitability-wrapper'>
            <DetailTable>
                <tbody>
                <tr>
                    <td>Вложено, руб</td>
                    <td span={6} className='ip-total-col'>{investmentTotal.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency', currency
                    })}</td>
                </tr>
                <tr>
                    <td span={8}>Стоимость, руб</td>
                    <td span={6} className='ip-total-col'>{currentTotal.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency', currency
                    })}</td>
                </tr>
                <tr>
                    <td span={8}>Прибыль, руб</td>
                    <td span={6} className='ip-total-col'>{profitTotal.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency', currency
                    })}</td>
                </tr>
                <tr>
                    <td span={8}>Прибыль, %</td>
                    <td span={6} className='ip-total-col'>
                        {profit.toLocaleString('ru-RU',
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: 'percent',
                            })}
                    </td>
                </tr>
                <tr>
                    <td span={8}>Доходность, %</td>
                    <td span={6} className='ip-total-col'>
                        {(annualReturn).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            style: 'percent',
                        })}
                    </td>
                </tr>
                </tbody>
            </DetailTable>
        </div>
    )
}

export default Profitability;
