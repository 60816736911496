import React, {useContext, useEffect} from 'react';
import BondsFilter from "./BondsFilter";
import {useDispatch, useSelector} from "react-redux";
import {selectBondsFilter, selectBonds, selectBondsPagination, selectBondsTableFilters, loadBonds} from "./bondsSlice";

const BondsSider = () => {
    const filters = useSelector(selectBondsFilter);
    const pagination = useSelector(selectBondsPagination);
    const tableFilters = useSelector(selectBondsTableFilters);
    const sorting = useSelector(state => state.bonds.sorting);

    const dispatch = useDispatch();

    useEffect(() => {
        loadBonds(filters, pagination, tableFilters, sorting, dispatch);
    }, []);

    return(
        <div>
            <BondsFilter
                values={filters}
                onChange={(values) => {
                    const newPagination = {
                        ...pagination,
                        current: 0
                    }
                   loadBonds(values,newPagination, tableFilters, sorting, dispatch);
                }}
            />
        </div>
    )
}

export default BondsSider;
