import React from 'react'
import './TotalReturn.less'

const TotalReturn = ({investmentTotal = 0, currentTotal = 0, profitTotal = 0,
                         annualReturn = 0, currency = 'RUB'}) => {
    return(
        <table className='ip-total-return-table'>
            <tbody>
            <tr>
                <td>Вложено, руб</td>
                <td span={6} className='ip-total'>{investmentTotal.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency', currency
                })}</td>
            </tr>
            <tr>
                <td span={8}>Стоимость, руб</td>
                <td span={6} className='ip-total'>{currentTotal.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency', currency
                })}</td>
            </tr>
            <tr>
                <td span={8}>Прибыль, руб</td>
                <td span={6} className='ip-total'>{profitTotal.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency', currency
                })}</td>
            </tr>
            <tr>
                <td span={8}>Доходность, %</td>
                <td span={6} className='ip-total'>
                    {(annualReturn).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'percent',
                    })}
                </td>
            </tr>
            </tbody>
        </table>
    )
}

export default TotalReturn;
