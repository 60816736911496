import React, {useEffect, useState} from 'react'
import AssetViewerTools from "./AssetViewerTools";
import Assets from "./Assets";

import './AssetViewer.less'
import AssetDialog from "./AssetDialog";
import {useDispatch, useSelector} from "react-redux";
import {delAsset, loadAssets, loadBrokers, saveAsset} from "./assetsSlice";


const AssetViewer = () => {

    const [visibleDialog, setVisibleDialog] = useState(false);
    const [editedAsset, setEditedAsset] = useState({});

    const dispatch = useDispatch();
    const assets = useSelector(state => state.assets.assets);
    const assetTypes = useSelector(state => state.assets.assetTypes);
    const brokers = useSelector(state => state.assets.brokers);
    const activeOnly = useSelector(state => state.assets.activeOnly);

    useEffect(() => {
        loadAssets(activeOnly, dispatch);
        loadBrokers(dispatch);
    },[])


    return (
        <>
            <div className='ip-assetviewer'>
                <AssetViewerTools
                    createAsset = {() => {
                        setEditedAsset({});
                        setVisibleDialog(true);
                    }}
                    onActive={checked => {
                        loadAssets(checked, dispatch);
                    }}
                    activeOnly={activeOnly}
                />
                <Assets
                    onEdit={(asset) => {
                        setEditedAsset(asset);
                        console.log(asset.name);
                        setVisibleDialog(true);
                    }}
                    onDelete={asset => {
                        const {id} = asset;
                        delAsset(id, dispatch);
                    }}
                    assets={assets}
                />
            </div>

            <AssetDialog
                visible={visibleDialog}
                data={editedAsset}
                assetTypes={assetTypes}
                brokers={brokers}
                dialogHandler={(result, values) => {
                    setVisibleDialog(false);
                    if (result)
                        saveAsset(values, dispatch);
                }}
            />
        </>
    )
}

export default AssetViewer;
