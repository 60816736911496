import React from "react";
import {Button, Col, Row, Tabs} from "antd";
import moment from "moment";
import DetailTable from "../../components/detailtable/DetailTable";
import BondDetailHeader from './BondDetailsHeader'
import './BondDetails.less';

const TabPane = Tabs.TabPane;


const BondDetails = ({bond = {}, onEditIssuer = () => {}}) => {
    const { isin, fullName, coupons = [], amortizations = [], offers = [], assetItems = [] } = bond;
    const issuer = bond.issuer ? bond.issuer : {};
    const { name : issuerName, idissuer, reliabilityDescription, economyBranch = {}, issuerLinks =[] } = issuer;

    const currentDate = moment.now();

    const showAmortization = () => {
        let list = amortizations === null ? [] : amortizations;
        const filteredAmortizations = list.filter( amortization =>
            moment(amortization.date) > currentDate
        );
        const currentAmortizationDate = filteredAmortizations.length ? filteredAmortizations[0].date : undefined;

        return list.map(amortization => {
            console.log(amortization.date === currentAmortizationDate);
            const key = moment(amortization.date).format('DD.MM.YYYY');
            return (
                <tr key={key} className={ amortization.date === currentAmortizationDate ? 'currentPeriod' : null }>
                    <td>{
                        moment(amortization.date).format('DD.MM.YYYY')
                    }</td>
                    <td>{amortization.value}</td>
                    <td>{amortization.percent}</td>
                </tr>
            )
        })
    };

    const showOffers = () => {
        const currentOffer = offers.filter( offer =>
            moment(offer.date) > currentDate
        )[0];

        return offers.map(offer => {
            return (
                <tr key={offer.date} className={ offer === currentOffer ? 'currentPeriod' : null }>
                    <td >{
                        moment(offer.date).format('DD.MM.YYYY')
                    }</td>
                    <td >{
                        offer.startDate ? moment(offer.startDate).format('DD.MM.YYYY') : null
                    }</td>
                    <td >{
                        offer.endDate ? moment(offer.endDate).format('DD.MM.YYYY') : null
                    }</td>
                    <td>{offer.type}</td>
                </tr>
            )
        });
    };

/*    const issuerLinksViewer = () => {
        if( issuerLinks ) {
            return(
                <Col>
                    <h5>Ссылки</h5>
                    <ul>
                        { issuerLinks.map( link =>
                            <li><a href={link.url} target='_blank'>{link.site.name}</a></li> )
                        }
                    </ul>
                </Col>
            );
        }
    }*/

    return (
        <div className='ip-bond-detail'>
            <BondDetailHeader bond={bond} />
            <Tabs>
                <TabPane tab="Купон" key="coupons">
                    <DetailTable>
                        <thead>
                            <tr>
                                <th>Начало</th>
                                <th>Дата</th>
                                <th>Дата фиксации</th>
                                <th>Размер выплаты</th>
                                <th>Ставка купона, %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {coupons.map(coupon => {
                                const currentClassName = ( currentDate >= moment(coupon.startDate) && currentDate <= moment(coupon.date) ) ?
                                    'currentPeriod' : null;
                                const key = moment(coupon.date).format('DD.MM.YYYY');
                                return (
                                    <tr key={key} className={currentClassName}>
                                        <td >{
                                            coupon.startDate ? moment(coupon.startDate).format('DD.MM.YYYY') : null
                                        }</td>
                                        <td >{
                                            key
                                        }</td>
                                        <td >{
                                            (coupon.recordDate) ? moment(coupon.recordDate).format('DD.MM.YYYY') : null
                                        }</td>
                                        <td>{coupon.value}</td>
                                        <td>{coupon.percent}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </DetailTable>
                </TabPane>
                <TabPane tab="Амортизация" key="amortizations">
                    <DetailTable>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Размер выплаты</th>
                                <th>Ставка, %</th>
                            </tr>
                        </thead>
                        <tbody>
                        {showAmortization()}
                        </tbody>
                    </DetailTable>
                </TabPane>
                <TabPane tab="Оферта" key="offerts">
                    <DetailTable>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Начало</th>
                                <th>Окончание</th>
                                <th>Тип</th>
                            </tr>
                        </thead>
                        <tbody>
                        {showOffers()}
                        </tbody>
                    </DetailTable>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default BondDetails;
