import React  from 'react'

import './ReportWidget.less'


const ReportWidget = ({caption, children}) => {

    return(
        <div className='ip-report-widget'>
            <div className='header'>
                <h4>{caption}</h4>
            </div>
            <div className='body'>
                {children}
            </div>
        </div>
    )
}

export default ReportWidget;
