import React, {useEffect, useState} from 'react';
import {Checkbox, Form, Input, Modal, Select, Switch} from "antd";
const { Option } = Select;

const formItemLayout = {
    wrapperCol: {offset: 5, span: 19},
};

const tailItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
};


const AssetDialog = ({  visible = false, data = {},
                        dialogHandler = () => {}, assetTypes = [],
                        brokers = []
                     }) => {
    const {id, name, assetType, isActive, broker} = data;
    const [form] = Form.useForm();
    const [currentAssetType, setCurrentAssetType] = useState({});



    useEffect(() => {
        const initAssetType = assetType ?
            assetTypes.filter(value => value.id === assetType).at(0)
            : assetTypes.length > 0 ? assetTypes[0] : {};
        setCurrentAssetType(initAssetType);

        form.setFieldsValue({
            name,
            assetType : initAssetType,
            isActive,
            broker
        })
    }, [data]);


    const onOk = () => {
        form.validateFields().then(values => {
            values = {
                ...data,
                ...values,
                assetType : values.assetType.id
            }
            dialogHandler(true, {...values})
        });
    }

    const brokerFields = () => {
        const {id} = currentAssetType;
        if( id === 'STOCK_EXCHANGE' ) {
            return(
                <Form.Item label="Брокер" {...tailItemLayout}
                           name={['broker','id']}
                    >
                    <Select>
                        {brokers.map( broker => <Option key={broker.id}>{broker.name}</Option> )}
                    </Select>
                </Form.Item>
            )
        }
    }


    return (
        <Modal
            title= {id ? 'Редактирование актива': 'Новый актив'}
            className="uk-panel-title"
            open={visible}
            onCancel={() => dialogHandler(false)}
            onOk={onOk}
            width={600}
        >
            <Form form={form}
                  initialValues={{
                      name,
                      assetType : currentAssetType,
                      isActive,
                      broker
                  }}>

                <Form.Item label="Наименование"  {...tailItemLayout}
                          name='name'
                          rules={[{required: true, message: 'Пожалуйста, введите наименование.'}]}>
                    <Input placeholder="Введите наименование" />
                </Form.Item>

                <Form.Item label="Тип актива" {...tailItemLayout}
                          name={['assetType','id']}
                          rules={[{required: true, message: 'Пожалуйста, опередите тип актива'}]}>
                    <Select disabled={id? true : false} onChange={(id)=> setCurrentAssetType({id})}>
                        {assetTypes.map( type => <Option key={type.id}>{type.name}</Option> )}
                    </Select>
                </Form.Item>

                <Form.Item {... formItemLayout}
                    name='isActive'
                    valuePropName='checked' >
                    <Checkbox >
                        Активный
                    </Checkbox>
                </Form.Item>

                { brokerFields() }
            </Form>
        </Modal>
    );

}

export default AssetDialog;
