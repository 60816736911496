import React from 'react'
import './PageHeader.less'

const PageHeader = ({caption, children}) => {
    return(
        <div className="ip-page-header">
            <h1>{caption}</h1>
            {children}
        </div>
    )
}

export  default PageHeader;
