import React, {useEffect} from 'react';
import Asset from "./Asset";
import {Row, Col} from "antd";
import {useDispatch} from "react-redux";
import TotalReturn from "./TotalReturn";

import {assetTotalReturnAsync} from "../assetsSlice";


const Deposit = ({asset, onEdit=()=> {}, onDelete}) => {
    const {description, id, totalReturn = {}} = asset;
    const {investmentTotal = 0, currentTotal = 0, profitTotal = 0, annualReturn = 0} = totalReturn;
    const currency = 'RUB';

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(assetTotalReturnAsync({assetId : id}));
    }, [dispatch, id]);

    return (
        <Asset
            asset={asset}
            onEdit={onEdit}
            onDelete={onDelete}
            assetTypeName='Депозит'
        >
            <TotalReturn
                currency={currency}
                investmentTotal={investmentTotal}
                currentTotal={currentTotal}
                profitTotal={profitTotal}
                annualReturn={annualReturn}
            />
        </Asset>
    )
}

export default Deposit;
