import React from 'react'
import DepositTransactionsPage from "./DepositTransactionsPage";
import StockExchangeTransactionsPage from "./stockexchange/StockExchangeTransactionsPage";


export default class TransactionsFactory {

    static build(asset) {
        switch (asset.assetType) {
            case 'DEPOSIT':
                return <DepositTransactionsPage asset={asset}/>;
            case 'STOCK_EXCHANGE':
                return <StockExchangeTransactionsPage asset={asset}/>;
            default:
                return undefined;
        }
    }
}
