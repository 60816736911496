import React from 'react';
import {Menu} from "antd";

const HomeHeader = ({items}) => {
    return(
        <div className='home-page-header'>
            <div className='home-page-logo'>
                I-Portfolio
            </div>
            <Menu
                className='home-page-menu'
                mode='horizontal'
                //defaultSelectedKeys={['entrance']}
                theme="light"
                items={items}
                onClick={({ item, key, keyPath, domEvent }) => {
                    console.log('click ', key);
                }}
            />
        </div>
    )
}

export default HomeHeader;
