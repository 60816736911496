import React from 'react';
import {Menu} from "antd";
import {Link, useLocation} from "react-router-dom";

const items = [
    {
        label: <Link to='report'>Обзор</Link>,
        key: 'portfolio/report',
    },
    {
        label:
            <Link to='assets'>Активы</Link>,
        key: 'portfolio/assets',
    },
    {
        label:
            <Link to='transactions'>Сделки</Link>,
        key: 'portfolio/transactions',
    },
    {
        label: <Link to='history'>История</Link>,
        key: 'portfolio/history',
    },
]

const PortfolioSider = () => {
    const location = useLocation();

    const keys = (path) => {
        var segments = path.split('/');
        return segments[2] + '/'+ segments[3];
    }


    return(
        <Menu items={items}
              selectedKeys={keys(location.pathname)}
        />
    )
}

export default PortfolioSider;
