import React from "react";
import './FilterGroupContainer.less';

const FilterGroupContainer = ({groupTitle, children}) => {
    return(
        <div className='filter-group'>
            <h5 className='filter-group-header'>{groupTitle}</h5>
            <div >
                {children}
            </div>
        </div>
    );
};

export default FilterGroupContainer;
