import React, {useCallback, useEffect, useState} from 'react';

const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const PortfolioHistoryPage = () => {
    const [helmet] = useState(initHelmet);

    return(
        <>
            История
        </>
    )
}

export default PortfolioHistoryPage;
