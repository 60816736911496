import React from "react";
import {Checkbox, Col, Row} from "antd";
import {FilterContainer, FilterGroupContainer} from '../../components/filtercontainer'

const BondsFilter = ( {onChange = () => {}, values = [], reliabilityTypes = [] }) => {

    const {durations = [], rates = [], reliability = [], bondTypes = [], active = [], offerTypes = [], additional = [] } = values;

    return(
        <FilterContainer title='Фильтр'>
            <FilterGroupContainer groupTitle='Расчетная дата'>
                <Checkbox.Group
                    defaultValue={durations}
                    onChange={(filterValues) => onChange({
                        ...values,
                        durations : filterValues,
                    })}
                >
                    <Row><Col><Checkbox value="5">более 5 лет</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="35">3-5 лет</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="23">2-3 года</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="12">1-2 года</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="1">менее 1 года</Checkbox></Col></Row>
                </Checkbox.Group>
            </FilterGroupContainer>
            <FilterGroupContainer groupTitle='Доходность'>
                <Checkbox.Group
                    defaultValue={rates}
                    onChange={(filterValues) => onChange({
                        ...values,
                        rates : filterValues,
                    })}
                >
                    <Row><Col><Checkbox value="30">более 30%</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="2030">20-30%</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="1520">15-20%</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="1015">10-15%</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="10">менее 10%</Checkbox></Col></Row>
                </Checkbox.Group>
            </FilterGroupContainer>
            <FilterGroupContainer groupTitle='Есть предложения'>
                <Checkbox.Group
                    defaultValue={offerTypes}
                    onChange={(filterValues) => onChange({
                        ...values,
                        offerTypes : filterValues,
                    })}
                >
                    <Row><Col><Checkbox value="ask">Покупки</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="bid">Продажи</Checkbox></Col></Row>
                </Checkbox.Group>
            </FilterGroupContainer>
            <FilterGroupContainer groupTitle='Тип'>
                <Checkbox.Group
                    defaultValue={bondTypes}
                    onChange={(filterValues) => onChange({
                        ...values,
                        bondTypes : filterValues,
                    })}
                >
                    <Row><Col><Checkbox value="corporation">Корпоративные</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="municipal">Муниципальные</Checkbox></Col></Row>
                    <Row><Col><Checkbox value="state">Государственные</Checkbox></Col></Row>
                </Checkbox.Group>
            </FilterGroupContainer>
            {/*<FilterGroupContainer groupTitle='Дополнительно'>*/}
            {/*    <Checkbox.Group*/}
            {/*        defaultValue={additional}*/}
            {/*        onChange={(filterValues) => onChange({*/}
            {/*            ...values,*/}
            {/*            additional: filterValues*/}
            {/*        })}*/}
            {/*    >*/}
            {/*        <Row><Col><Checkbox value="portfolioOnly">В портфеле</Checkbox></Col></Row>*/}
            {/*        <Row><Col><Checkbox value="shortlistOnly">Шортлист</Checkbox></Col></Row>*/}
            {/*    </Checkbox.Group>*/}
            {/*</FilterGroupContainer>*/}
            <FilterGroupContainer groupTitle='Надежность'>
                <Checkbox.Group
                    defaultValue={reliability}
                    onChange={(filterValues) => onChange({
                        ...values,
                        reliability: filterValues,
                    })}
                >
                    { reliabilityTypes.map( reliability => <Row key={reliability.id}><Col><Checkbox key={reliability.id} value={reliability.id}>{reliability.name}</Checkbox></Col></Row> ) }
                </Checkbox.Group>
            </FilterGroupContainer>
            <FilterGroupContainer groupTitle='Только активные'>
                <Checkbox.Group
                    defaultValue={active}
                    onChange={(filterValues) => onChange({
                        ...values,
                        active: filterValues
                    })}
                >
                    <Row><Col><Checkbox value="active">Да</Checkbox></Col></Row>
                </Checkbox.Group>
            </FilterGroupContainer>
        </FilterContainer>
    );
};

export default BondsFilter;
