import React, {useEffect, useState} from 'react'
import {ReportWidget} from "../../components/portfolioReports";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts';
import {useDispatch, useSelector} from "react-redux";
import {assetTypeAllocationsAsync} from "./portfolioReportSlice";
import DetailTable from "../../components/detailtable/DetailTable";


const AssetTypeAllocationReport = () => {
    const assetTypeAllocations = useSelector(state => state.portfolioReport.assetTypeAllocations);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(assetTypeAllocationsAsync());
    }, [dispatch]);


    const total = assetTypeAllocations.reduce((accumulator, {total}) => {
        return accumulator + total
    },0);

    const getData = () => {
        return assetTypeAllocations.map( item => {
            const {total: value = 0, name} = item;
            const distance = (value / total * 100) > 30 ? -50 : 30;
            return {
                name,
                y: value / total,
                dataLabels: {
                    distance: distance,
                }}
        })
    }

    const getPieColors = () => Highcharts.getOptions().colors.map((c, i) =>
        Highcharts.color(Highcharts.getOptions().colors[0])
            .brighten((i - 3) / 7)
            .get()
    );

    const getChartOptions = () => {
        return({
            chart: {
                type: 'pie',
            },
            title: {
                text: ''
            },
            // subtitle: {
            //     text: subtitle
            // },
            tooltip: {
                headerFormat: '',
                pointFormat: '<b>{point.name}:</b> {point.percentage:.1f}%'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    colors : getPieColors(),
                    cursor: 'pointer',
                    animation: false,
                    size: '75%',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                    },
                    showInLegend: false
                }
            },
            series: [{
                data: getData()
            }]
        });
    };

    const getDetailData = () => {
        return assetTypeAllocations.map(item => {
            const {total = 0, name, currency} = item;
            return (
                <tr>
                    <td span={8}>{name}</td>
                    <td span={6} className='total-col'>{total.toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency', currency
                    })}</td>
                </tr>
            )
        })
    }

    const getChart = () => {
        if(assetTypeAllocations.length === 0) {
            return <></>
        }
        else {
            return <HighchartsReact
                highcharts={Highcharts}
                options={getChartOptions()}
            />
        }
    }

    return (
        <ReportWidget caption='По типам активов'>
            <div className='legend'>
                <DetailTable>
                    <tbody>
                    {getDetailData()}
                    </tbody>
                </DetailTable>
            </div>
            <div className='chart'>
                {getChart()}
            </div>
        </ReportWidget>
    )
}

export default AssetTypeAllocationReport;
