import React from 'react'
import {Tabs} from "antd";
import {Transactions} from "../transactions";
import {useDispatch} from "react-redux";
import {loadAssetDetailInfoAsync} from "../transactionsSlice";
import StockExchangeDetailInfo from "./StockExchangeDetailInfo";
import BrokerReportLoading from "./BrokerReportLoading";
import BrokerReportLoadHistory from "./BrokerReportHistory";


const StockExchangeTransactionsPage = ({asset}) => {
    const dispatch = useDispatch();
    const {detailInfo} = asset;

    const tabs = [
        {
            key: 'transactions',
            label: "Сделки",
            children: <Transactions asset={asset} />,
        },
        {
            key: 'sec',
            label: "Инструменты",
            children: <StockExchangeDetailInfo detailInfo={detailInfo} />,
        },
        {
            key: 'upload',
            label: "Загрузка",
            children: <BrokerReportLoading asset={asset} />,
        },
        {
            key: 'uploadHistory',
            label: "История загрузки",
            children: <BrokerReportLoadHistory asset={asset} />,
        },
    ];

    return(
        <Tabs defaultActiveKey="1"
              onChange={(activeKey) => {
                  if (  activeKey === 'sec' ) {
                      dispatch(loadAssetDetailInfoAsync({asset}));
                  }
                  // else if (activeKey==='4') {
                  //     onLoadReports(currentPortfolio);
                  // }
              }}
              items={tabs}
        />
    );
}

export default StockExchangeTransactionsPage;
