import React, {useCallback, useEffect, useState} from 'react';
import {MainLayout} from "../../layouts/MainLayout";
import {Outlet} from "react-router-dom";

const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const MainPage = () => {
    const [helmet] = useState(initHelmet);


    return(
        <MainLayout
            helmet={helmet}
        >
            Здесь будет ваш портфель
            <Outlet/>
        </MainLayout>
    )
}

export default MainPage;
