import React from "react";
import './DetailTable.less';

const DetailTable = ({props, children}) => {
    return(
        <table className="ip-detail-table">
            {children}
        </table>
    );
};

export default DetailTable;
