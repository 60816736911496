import React from 'react'
import {Button, Input, Space, DatePicker, Row} from "antd";
import Highlighter from "react-highlight-words";
import {Icon} from "@iconify/react";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const getColumnSearchProps = (dataIndex, searchInput,
                              handleSearch = () => {}, handleReset = () => {},
                              searchDateRange = [], setSearchDateRange = () => {}
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
                padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <div style={{marginBottom: 8, display: 'block',}}>
                <RangePicker
                    format={'DD.MM.YYYY'}
                    value={selectedKeys.map(d => dayjs(d))}
                    onChange={(dates) => {
                        const rd = dates.map( d => d.format('YYYY-MM-DD'));
                        setSelectedKeys(rd)
                    }}
                />
            </div>
            <div className='ant-table-filter-dropdown-btns'>
                <Button type="link" onClick={() => handleReset(clearFilters)} size="small" >
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        //const rd = selectedKeys.map( d => d.format());
                        handleSearch(selectedKeys, confirm);
                    }}
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
            </div>
        </div>
    ),
    onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },
    // filteredValue: searchDateRange.map(d => dayjs(d)),
    onFilter: (value, record) => true
});

export default getColumnSearchProps;
