import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    assets,
    createAsset,
    updateAsset,
    deleteAsset,
    brokers,
    assetDetailInfo,
    updateReportLoaderParams, assetTotalReturn
} from '../../services/apiService'
import {currentAssetTotalReturnAsync} from "../portfolioTransactions/transactionsSlice";

export const assetsSlice = createSlice({
    name: 'assets',
    initialState: {
        brokers: [],
        filters: {
        },
        loading : false,
        assets: [],
        assetTypes: [
            {id: 'DEPOSIT', name: 'Депозит'},
            {id: 'STOCK_EXCHANGE', name: 'Брокерский счет'}
        ],
        sorting: {
            field: 'name',
            order: ''
        },
        activeOnly: true
    },
    reducers: {
        request: (state, {payload: {filters, tableFilters, sorting}}) => {
            state.filters = filters;
            state.tableFilters = tableFilters;
            state.sorting = sorting;
        },
        addAsset: (state, {payload}) => {
            state.assets = [...state.assets, payload];
        },
        editAsset: (state, {payload}) => {
            state.assets = state.assets.map(asset => {
                if(asset.id === payload.id)
                    return payload;
                else
                    return asset;
            })
        },
        delAsset: (state, {payload}) => {
            state.assets = state.assets.filter( asset => asset.id !== payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAsyncAssets.pending, (state, action) => {
                const {activeOnly} = action.meta.arg;
                console.log('assets.loadAssets.pending');
                state.loading = true;
                state.activeOnly = activeOnly;
            })
            .addCase(loadAsyncAssets.fulfilled, (state, action) => {
                console.log('assets.loadAssets.fulfilled');
                state.assets = action.payload.map(
                    asset => {
                        const prevAssetData =  state.assets.find( a => asset.id === a.id);
                        if(prevAssetData)
                            return prevAssetData;
                        else
                            return asset;
                    });
                state.loading = false;
            })
            .addCase(loadAsyncAssets.rejected, (state, action) => {
                console.log('assets.loadAssets.rejected');
                state.assets = [];
                state.loading = false;
            })
            .addCase(loadAsyncBrokers.fulfilled, (state, action) => {
                console.log('assets.loadAssets.fulfilled');
                state.brokers = action.payload;
            })
            .addCase(loadAsyncBrokers.rejected, (state, action) => {
                console.log('assets.loadBrokers.rejected');
                state.brokers = [];
            })
            .addCase(assetTotalReturnAsync.pending, (state, action) => {
                const {assetId} = action.meta.arg;
                state.assets = state.assets.map(
                    asset => asset.id === assetId ?
                        {
                            ...asset,
                            loading: true
                        }
                        : asset
                );
            })
            .addCase(assetTotalReturnAsync.fulfilled, (state, action) => {
                const {assetId} = action.meta.arg;
                state.assets = state.assets.map( asset => asset.id === assetId ?
                    {
                        ...asset,
                        totalReturn: action.payload,
                        loading: false
                    }
                    : asset
                );
            })
    }
})

const { addAsset, editAsset, delAsset : deleteAssetReducer } = assetsSlice.actions;

const loadAsyncAssets = createAsyncThunk('assets/loadAsyncAssets', ({activeOnly}) => {
    return assets(activeOnly);
})

const loadAsyncBrokers = createAsyncThunk('assets/loadAsyncBrokers', () => {
    return brokers();
})

export const assetTotalReturnAsync = createAsyncThunk('assets/assetTotalReturnAsync', ({assetId}) => {
    return assetTotalReturn(assetId);
})


export const loadAssets = (activeOnly, dispatch) => {
    try{
        dispatch(loadAsyncAssets({activeOnly}))
    }
    catch (e) {
        console.log(e)
    }
}

export const saveAsset = (asset, dispatch) => {
    if(asset.id) {
        updateAsset(asset).then(updatedAsset => {
            dispatch(editAsset(updatedAsset));
        })
    }
    else {
        createAsset(asset).then( createdAsset => {
            dispatch(addAsset(createdAsset));
        })
    }
}

export const patchReportLoaderParams = (assetId, loaderParams, dispatch) => {
    updateReportLoaderParams(assetId, loaderParams).then((updatedAsset) => {
        dispatch(editAsset(updatedAsset));
    })
}

export const delAsset = (id, dispatch) => {
    if(id)
        deleteAsset(id).then(() => {
            dispatch(deleteAssetReducer(id));
        })
}


export const loadBrokers = (dispatch) => {
    try{
        dispatch(loadAsyncBrokers())
    }
    catch (e) {
        console.log(e)
    }
}


export default assetsSlice.reducer
