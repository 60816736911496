import React from 'react'
import {Button, Input, Space, DatePicker, Row, Col, Select, Spin} from "antd";
import Highlighter from "react-highlight-words";
import {Icon} from "@iconify/react";
import dayjs from 'dayjs';
import {securitySearch} from "../../../services/apiService";

const { RangePicker } = DatePicker;
const Option = Select.Option;


const getColumnSearchProps = (dataIndex, searchInput,
                              handleSearch = () => {}, handleReset = () => {},
                              fetching = false,
                              secType = "SHARE", setSecType = () => {},
                              secSearchData = [], fetchSec = () => {}
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
                padding: 8, minWidth: 400,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <div style={{marginBottom: 8, display: 'block',}}>
                <Row  style={{marginBottom: 8}}>
                    <Col span={4}>
                        Тип
                    </Col>
                    <Col span={20}>
                        <Select
                            onChange={setSecType}
                            value={secType}
                            style={{width: '100%'}}
                        >
                            <Option key="SHARE" value="SHARE">Акции</Option>
                            <Option key="BOND" value="BOND">Облигация</Option>
                            <Option key="FUND" value="FUND">ETF</Option>
                            <Option key="FUTURE" value="FUTURE">Фьючерс</Option>
                        </Select>
                    </Col>
                </Row>
                <Row  style={{marginBottom: 8}}>
                    <Col span={4}>
                        Актив
                    </Col>
                    <Col span={20}>
                        <Select  prefix={<Icon icon='iconamoon:lock-light' style={{color: 'rgba(0,0,0,.25)'}}/>}
                                 showSearch
                                 placeholder="Выберите акцию"
                                 optionFilterProp="children"
                                 filterOption={(input, option) =>
                                     true
                                 }
                                 notFoundContent={fetching ? <Spin size="small"/> : null}
                                 onSearch={fetchSec}
                                 value={selectedKeys[0]}
                                 onChange={(value) => {
                                     setSelectedKeys(value ? [value] : [])
                                 }}
                                 style={{width: '100%'}}
                        >

                            {secSearchData.map(d => {
                                const op = <Option key={d.id} value={d.id}>{`${d.shortname} (${d.ticker})`}</Option>
                                console.log(op);
                                return op;
                            })}
                        </Select>
                    </Col>
                </Row>
            </div>
            <div className='ant-table-filter-dropdown-btns'>
                <Button type="link" onClick={() => handleReset(clearFilters)} size="small" >
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        handleSearch(selectedKeys, confirm);
                    }}
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
            </div>
        </div>
    ),
    onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },
    onFilter: (value, record) => true
});

export default getColumnSearchProps;
