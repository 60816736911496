import React, {useCallback, useEffect, useState} from 'react';
import BondsSider from "./BondsSider";
import {Layout, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectBondsFilter, selectBondsPagination, selectBondsTableFilters, loadBonds} from "./bondsSlice";
import moment from "moment";
import TableWrapper from "../../components/antdWrappers/TableWrapper";
import BondDetails from "./BondDetails";
import withSearchFunction from "../../components/filterColumnSearch/with-search-function";

const {Content, Sider} = Layout;


const initHelmet = {
    isRoot: false,
    description: 'Demo using Ant design in React',
    title: 'I-Portfolio'
};

const BondsPage = ({columnSearchProps}) => {
    const columns = [
        {
            ...columnSearchProps('облигации', true),
            title:
                <React.Fragment>
                    <div>Наименование</div>
                    <div><span className='secondary title'>ISIN</span></div>
                </React.Fragment>
            , dataIndex: 'isin'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        <div>
                            {record.name}
                        </div>
                        <div><span className='secondary'>{record.isin}</span></div>
                    </React.Fragment>)
            }
        }
        , {
            title:
                <React.Fragment>
                    <div>Доходность(полная), %</div>
                    {/*<div><span className='secondary title'>По посл.цене / По предл.</span></div>*/}
                </React.Fragment>
            , dataIndex: 'yield'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        <div>{record.yield} %</div>
                        {/*<div><span className='secondary'>{record.simpleYieldLastprice} / {record.simpleYieldOffer}</span></div>*/}
                    </React.Fragment>);
            }
            , sorter : true
            , defaultSortOrder : 'descent'
        }
        , {
            title:
                <React.Fragment>
                    <div>Оферта</div>
                    <div><span className='secondary title'>Закрытие</span></div>
                </React.Fragment>
            , dataIndex: 'finishDate'
            , render: (text, record) => {
                if(record.offerDate)
                    return (
                        <React.Fragment>
                            <div>{moment(record.offerDate).format('DD.MM.YYYY')}</div>
                            <div><span className='secondary'>{moment(record.finishDate).format('DD.MM.YYYY')}</span></div>
                        </React.Fragment>);
                else
                    return (<div>{moment(record.finishDate).format('DD.MM.YYYY')}</div>);
            }
            , sorter : true
        }
        , {
            title:
                <React.Fragment>
                    <div>Купон, %</div>
                    <div><span className='secondary title'>НКД / Период / Купон</span></div>
                </React.Fragment>
            , dataIndex: 'couponPercent'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        <div>{record.couponPercent} %</div>
                        <div><span className='secondary'> {record.aci} / {record.couponPeriod} / {record.couponValue}</span></div>
                    </React.Fragment>);
            }
            , sorter : true
        }
        , {
            title:
                <React.Fragment>
                    <div>Цена, %</div>
                    <div><span className='secondary title'>Ask/Bid</span></div>
                </React.Fragment>
            , dataIndex: 'earningyear'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        <div>{record.price}</div>
                        <div><span className='secondary'>{record.ask}/{record.bid}</span></div>
                    </React.Fragment>);
            }
        }
        , {
            title:
                <React.Fragment>
                    <div>Валюта</div>
                </React.Fragment>
            , dataIndex: 'currency'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        <div>{record.currency}</div>
                    </React.Fragment>);
            }
        }
        , {
            title:
                <React.Fragment>
                    <div>Дата купона</div>
                </React.Fragment>
            , dataIndex: 'couponDate'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        <div>{moment(record.couponDate).format('DD.MM.YYYY')}</div>
                    </React.Fragment>);
            }
        }
        , {
            title:
                <React.Fragment>
                    {/*<div><FontAwesomeIcon icon={faEllipsisH} /></div>*/}
                </React.Fragment>
            , dataIndex: 'edate'
            , align: 'left'
            , render: (text, record) => {
                return (
                    <React.Fragment>
                        {
                            // record.shortlistAmn ?
                                // <button className="shortlist-button ai-link-button"
                                //         onClick={() => deleteShortlist(record.isin)}>
                                //     <FontAwesomeIcon className="shortlist-included" icon={faMinusCircle}/>
                                // </button> :
                                // <button className="shortlist-button ai-link-button"
                                //         onClick={() => addShortlist(record.isin)}>
                                //     <FontAwesomeIcon icon={faPlusCircle}/>
                                // </button>
                        }
                        {/*{ record.portfolioAmn ? <span className='portfolio_included m_2_left'><FontAwesomeIcon icon={faCheckCircle} /></span>: undefined }*/}
                    </React.Fragment>
                );
            }
        }
    ];

    // const onChange = ( pagination, filters, sorter) => {
    //     onTableChange(pagination, filters);
    // };

    const dispatch = useDispatch();
    const filters = useSelector(selectBondsFilter);
    const pagination = useSelector(selectBondsPagination);
    const bonds = useSelector(state => state.bonds.bonds);
    const loading = useSelector(state => state.bonds.loading);

    return(
        <Layout hasSider className='main-layout-wrapper'>
            <Sider>
                <BondsSider />
            </Sider>
            <Content className='main-layout-content'>
                <TableWrapper>
                    <Table
                        columns={columns}
                        rowKey='id'
                        dataSource={bonds}
                        loading={loading}
                        pagination={pagination}
                        size="small"
                        bordered
                        onChange={( pagination, tableFilters, sorter) => {
                            const {field, order} = sorter;
                            const sort = { field, order };
                            loadBonds( filters, pagination, tableFilters, sort, dispatch);
                        }}
                        expandable={{
                            expandedRowRender: (record) => <BondDetails bond={record} />,
//                            rowExpandable: (record) => true,
                        }}
                    />
                </TableWrapper>
            </Content>
        </Layout>
    )
}

const BondListWithSearchFunction = withSearchFunction( BondsPage );

export default BondListWithSearchFunction;
