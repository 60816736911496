import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {Layout, Spin} from 'antd';
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from "react-redux";
import {initAndLogin, initApiService, login, selectIsAuthenticated, selectIsInit} from "../features/auth/authSlice";
import {HomeHeader, MainHeader} from "../components/header";
import {Link, Outlet} from "react-router-dom";
import {PortfolioFooter} from "../components/footer";
import './MainLayout.less';
import {loadAsyncCurrencies} from "../features/main/currenciesSlice";


const {Header, Content, Footer, Sider} = Layout;

const items = [
    {
        label:
            <Link to='portfolio/report'>Портфель</Link>,
        key: 'portfolio',
    },
    {
        label:
            <Link to='bonds'>Облигации</Link>,
        key: 'bonds',
    },
    {
        label:
            <Link to='shares'>Акции</Link>,
        key: 'shares',
    },
    {
        label: 'ETF',
        key: 'etf',
    }
]

export const MainLayout = (props) => {
    const {children, helmet, appSider = () => {}} = props;

    const [title, setTitle] = useState(helmet.title);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isInit = useSelector(selectIsInit);
    const keycloak = useSelector( state => state.auth.keycloak)

    useEffect(() => {
        if(!isInit) {
            initAndLogin('/app', dispatch);
        }
    }, [dispatch, isInit]);

    useEffect(() => {
        if(isInit)
            dispatch(loadAsyncCurrencies());

        keycloak.ontokenexpired = () => {
            console.log("token expired ...");
            keycloak.updatetoken(10000);
        };
    }, [dispatch, isInit]);

    useEffect(() => {
        if (helmet.isRoot) {
            setTitle('React Antd Demo');
        } else {
            setTitle(`${helmet.title} | React Antd Demo`);
        }
    }, [helmet]);

    if(!isAuthenticated) {
        return <Spin />;
    }
    return (
        <Layout className="main-layout">
            <>
                <Helmet>
                    <html lang="en"/>
                    <title>{title}</title>
                    <meta name="description" content={helmet.description}/>
                </Helmet>
            </>

            <Header>
                <MainHeader items={items}/>
            </Header>
            <Outlet/>
            <Footer>
                <PortfolioFooter/>
            </Footer>
        </Layout>
    );
};
