import React from 'react'
import './TableWrapper.less'

const TableWrapper = ({children}) => {
    return(
        <div className='ant-table-wrapper'>
            {children}
        </div>
    )
}

export  default TableWrapper;
