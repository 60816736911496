import React from "react";
import './FilterContainer.less';

const FilterContainer = ({children, title}) => {
    return(
        <div className='ai-filter'>
            <div className='filter-group-header'>{title}</div>
            {children}
        </div>
    );
};

export default FilterContainer;
