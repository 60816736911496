import React, {useEffect, useState} from 'react';

import {Button, Divider, Popconfirm, Table} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {
    getReportLoadHistoryItemsAsync, loadAsyncTransactions,
} from "../transactionsSlice";
import moment from "moment/moment";
import transactionDataColumnProps from "../transactions/transactionDateColumnProps";
import transactionDetailsColumnProps from "../transactions/transactionDetailsColumnProps";
import {Icon} from "@iconify/react";


const BrokerReportLoadHistory = ({asset}) => {

    const dispatch = useDispatch();
    const reportHistoryItems = useSelector(state => state.transactions.currentAsset.reportLoadHistoryItems);

    const {id: assetId} = asset;
    useEffect(() => {
        dispatch(getReportLoadHistoryItemsAsync({assetId}));
    }, [dispatch, assetId]);

    const itemsColumns = [
        {
            title: 'Дата'
            , dataIndex: 'edate'
            , render: (text, record) => {
                const {edate} = record;
                return (moment(edate).format('DD.MM.YYYY HH:mm:ss'));
            }
        },
        {
            title: 'Всего'
            , dataIndex: 'transactionsCnt'
        },
        {
            title: 'Ошибок'
            , dataIndex: 'errorsCnt'
        },
    ]

    return (
        <div className='ai-asset-page-context'>
            <Table
                caption={() => asset ? asset.name + ' (' + asset.description + ')' : ''}
                columns={itemsColumns}
                rowKey='id'
                dataSource={reportHistoryItems}
                // loading={loading}
                // pagination={pagination}
                // onChange={(pagination, filters, sorter) => {
                //     dispatch(loadAsyncTransactions({asset, pagination, filters}));
                // }}
                size="small"
                bordered
            />
        </div>
    )
}

export default BrokerReportLoadHistory;
