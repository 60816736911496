import React from 'react'
import {Transactions} from "./transactions";


const DepositTransactionsPage = ({asset}) => {

    return(
        <Transactions asset={asset} />
    )
}

export default DepositTransactionsPage;
