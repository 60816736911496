import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Input, message, Row, Upload} from "antd";
import {patchReportLoaderParams} from "../../portfolioAssets/assetsSlice";
import {addReportTransactions, patchCurrentReportLoader} from "../transactionsSlice";
import {uploadApiTransactions} from "../../../services/apiService";

import './TransactionApiLoader.less';

const TransactionApiLoader = ({asset, loader, reload= () => {}}) => {

    const {broker, isActive, reportLoaderParams = {}, name, id} = asset;
    const {apiToken = '', accountId = undefined, lastUpdate} = reportLoaderParams;
    const [newApiToken, setApiToken] = useState(apiToken)
    const [newAccountId, setAccountId] = useState(accountId)
    const dispatch = useDispatch();

    useEffect(() => {
        const {reportLoaderParams = {}} = asset;
        const {apiToken = '', accountId = undefined} = reportLoaderParams;
        setApiToken(apiToken);
        setAccountId(accountId);
    }, [asset]);

    const brokerApiRequester = () => {
        if (!isActive)
            return `Счет ${name} закрыт.`;
        else {
            return <div className='ip-transaction-api-loader-wrapper'>
                <Row gutter={8}>
                    <Col span={4}>
                        API токен:
                    </Col>
                    <Col span={12}>
                        <Input value={newApiToken} type='password' placeholder="Введите токен"
                               onChange={e => setApiToken(e.target.value)}/>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={4}>
                        Cчет Тинькофф:
                    </Col>
                    <Col span={12}>
                        <Input value={newAccountId} placeholder="Выберите счет"
                               onChange={e => setAccountId(e.target.value)}/>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={4}>
                        Обновлено:
                    </Col>
                    <Col span={12}>
                        {lastUpdate}
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Button type='primary'
                                onClick={e => {
                                    const params = {apiToken: newApiToken, accountId: newAccountId};
                                    patchReportLoaderParams(id, params, dispatch)
                                }}
                        >
                            Сохранить
                        </Button>
                    </Col>
                </Row>
                { apiToken && accountId ?
                    <Row>
                        <div>
                            <Button type='primary'
                                    onClick={(e) => {
                                        uploadApiTransactions(id).then(transactions => {
                                            // addReportTransactions(transactions, dispatch);
                                            reload();
                                        })
                                    }}
                            >
                                Загрузить сделки
                            </Button>
                        </div>
                    </Row>
                    : undefined
                }
            </div>
        }
    }

    return (
        <div>
            <div>{brokerApiRequester()}</div>
        </div>
    )
}

export default TransactionApiLoader;
