import React from 'react'
import {Col, Row} from "antd";

import './BondDetailsHeader.less'
import moment from "moment/moment";

const BondDetailsHeader = ({bond: {name, shortname, isin, figi, regnumber,
    currencyValue, currency,
    isQualifiedInvestors, listLevel, startDateMoex}}) => {

    const showRegnumber = () => {
        if(regnumber)
            return (
                <Col>
                    Гос.регистрационный номер: <span>{regnumber}</span>
                </Col>
            )
    }

    return (
        <div className='ip-bond-detail-header'>
            <h3>{name}</h3>
            <div className='ip-bond-shortname'>{shortname}</div>

            <div className='ip-bond-data'>
                <Row>
                    <Col span={4}>
                        ISIN: <span>{isin}</span>
                    </Col>
                    <Col span={4}>
                        FIGI: <span>{figi}</span>
                    </Col>
                    {showRegnumber()}
                </Row>
                <Row>
                    <Col span={4}>
                        Номинальная стоимость: <span>{currencyValue}</span>
                    </Col>
                    <Col span={4}>
                        Валюта: <span>{currency}</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        Начало торгов: <span>{moment(startDateMoex).format('DD.MM.YYYY')}</span>
                    </Col>
                    <Col span={4}>
                        Уровень листинга: <span>{listLevel}</span>
                    </Col>
                    <Col span={4}>
                        Для квал. инвесторов: <span>{isQualifiedInvestors}</span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default BondDetailsHeader;
